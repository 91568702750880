import React, {useState, useCallback} from "react";
import {DayPickerRangeController} from "react-dates";
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import styles from "./DateRangeSelector.module.css";
import moment from "moment-timezone";
import {
    customDayStyles,
    CustomNavPrev,
    CustomNavNext
} from "./DateRangeSelector.styles";

const START_DATE = "startDate";
const END_DATE = "endDate";

export default function DateRangeSelector({dateRange, onSetDateRange, maxDaysPast, timezone="UTC"}) {
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(dateRange[0]);
    const [endDate, setEndDate] = useState(dateRange[1]);
    const [focusedInput, setFocusedInput] = useState(START_DATE);

    const handleClickStartDate = useCallback(() => {
        setFocusedInput(START_DATE);
        setIsOpen(true);
    }, []);
    const handleClickEndDate = useCallback(() => {
        setFocusedInput(END_DATE);
        setIsOpen(true);
    }, []);
    const handleFocusChange = useCallback(focusedInput => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedInput(focusedInput || START_DATE);
    }, []);
    const handleDatesChange = useCallback(({startDate, endDate}) => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
            const tmp = startDate;
            startDate = endDate;
            endDate = tmp;
        }
        setStartDate(startDate);
        setEndDate(endDate);
    }, []);
    const handleClickClose = useCallback(() => {
        function clamp(d) {
            return moment.min(moment().tz(timezone).startOf("day"),
                moment.max(d, moment().tz(timezone).startOf("day").subtract(maxDaysPast, "days")));
        }
        if (!startDate)
            return;
        const d1 = clamp(startDate);
        const d2 = endDate ? clamp(endDate) : d1;
        setStartDate(d1);
        setEndDate(d2);
        setIsOpen(false);
        onSetDateRange([d1, d2]);
    }, [startDate, endDate, onSetDateRange, maxDaysPast]);
    
    const renderControls = () => {
        return (
            <section className="calendar_controls">
                <div className="action">
                    <button onClick={handleClickClose}>Ok</button>
                </div>
            </section>
        );
    };

    return (
        <div className={styles.root}>
            {isOpen && <div className={styles.backdrop}>
                <div className={styles.anchor}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <DayPickerRangeController
                                navPrev={<CustomNavPrev/>}
                                navNext={<CustomNavNext/>}
                                minimumNights={0}
                                onFocusChange={handleFocusChange}
                                onDatesChange={handleDatesChange}
                                focusedInput={focusedInput}
                                startDate={startDate}
                                endDate={endDate}
                                keepOpenOnDateSelect={false}
                                enableOutsideDays={false}
                                hideKeyboardShortcutsPanel={true}
                                numberOfMonths={1}
                                monthFormat="MMMM"
                                onOutsideClick={handleClickClose}
                                renderCalendarDay={props => (
                                    <CustomizableCalendarDay {...props} {...customDayStyles} />
                                )}
                                // isOutsideRange={(day) => day.isBefore(moment("20210428", "YYYYMMDD"))} 
                                renderCalendarInfo={renderControls}
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className={styles.dateRange}>
                <div onClick={handleClickStartDate}>{dateRange[0].format("ll")}</div>
                <div>To</div>
                <div onClick={handleClickEndDate}>{dateRange[1].format("ll")}</div>
            </div>
        </div>
    );
}