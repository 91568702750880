import React, {useCallback} from "react";
import styles from "./ChartTypeSelector.module.css";

export default function ChartTypeSelector({chartTypes, onSetType}) {
    const handleClickType = useCallback(e => onSetType(e.target.value), [onSetType]);

    return (
        <div className={styles.typeSelector}>
            {chartTypes.map(t => (
                <button key={t} value={t} onClick={handleClickType} className={styles[t.replace(":","_")]} />
            ))}
        </div>
    );
}