import React, {useState, useCallback, useMemo, useContext} from "react";
import Panel from "./Panel";
import AggregationSelector from "./AggregationSelector";
import TimeSelector from "./TimeSelector";
import ChartTypeSelector from "./ChartTypeSelector";
import ChartLoader from "./ChartLoader";
import LocationContext from "../contexts/LocationContext";
import useImmediate from "../hooks/useImmediate";
import styles from "./ChartPanel.module.css";

export default function ChartPanel({panelKey, aggregations, canMaximize, isMaximized, onSetMax, ...defaults}) {
    const loc = useContext(LocationContext);

    // Aggregation
    const [aggregation, setAggregation] = useState(defaults.aggregation);
    const handleSetAggregation = useCallback(v => setAggregation(v), []);
    useImmediate(() => setAggregation(defaults.aggregation), [defaults.aggregation]);

    // Location
    const [location, setLocation] = useState(defaults.location);
    const handleSetLocation = useCallback(v => setLocation(v), []);
    useImmediate(() => setLocation(defaults.location), [defaults.location]);

    // Get defaults.
    const def = useMemo(() => aggregations.find(a => a.key === aggregation), [aggregations, aggregation]);

    // Chart type
    const [chartType, setChartType] = useState(null);
    const [isChartTypeSelectorOpen, setIsChartTypeSelectorOpen] = useState(false);
    const handleOpenTypeSelector = useCallback(() => setIsChartTypeSelectorOpen(true), []);
    const handleCloseTypeSelector = useCallback(() => setIsChartTypeSelectorOpen(false), []);
    const handleSetChartType = useCallback(v => {
        setChartType(v);
        setIsChartTypeSelectorOpen(false);
    }, []);
    useImmediate(() => def && setChartType(def.defaultChartType), [def]);

    // Time type and range.
    const [timeOptions, setTimeOptions] = useState(def?.time);
    const [time, setTime] = useState(def?.defaultTime);
    const handleSetTime = useCallback(v => setTime(v), []);
    useImmediate(() => {
        if (def) {
            setTimeOptions(def.time);
            setTime(def.defaultTime);
        }
    }, [def]);

    // Show locations or not?
    const withLocations = useMemo(() => !!loc.byId[defaults.location], [loc, defaults]);
    // Maximize
    const handleClickMax = useCallback(() => onSetMax(panelKey), [onSetMax, panelKey]);
    const handleClickRestore = useCallback(() => onSetMax(null), [onSetMax]);
    useImmediate(() => {
        if (def?.maximizedChartType) {
            setChartType(isMaximized ? def.maximizedChartType : def.defaultChartType);
        }
    }, [isMaximized]);

    const header = (
        <div className={styles.header}>
            {!!def?.chartTypes ?
                <button className={`${styles.icon} ${styles["icon-chart"]}`} onClick={handleOpenTypeSelector}/>
                : <div/>
            }
            <AggregationSelector aggregation={aggregation} onSetAggregation={handleSetAggregation} location={location}
                                 onSetLocation={handleSetLocation} aggregations={aggregations}
                                 withLocations={withLocations}
            />
            {timeOptions?.length > 1 && <TimeSelector options={timeOptions} selected={time} onSetTime={handleSetTime}/>}
            {canMaximize && (isMaximized ?
                    <button className={`${styles.icon} ${styles["icon-close"]}`} onClick={handleClickRestore}/>
                    : <button className={styles.maximize} onClick={handleClickMax}/>
            )}
        </div>
    );
    const chartTypeSelectHeader = (
        <div className={styles.header}>
            <button className={`${styles.icon} ${styles["icon-close"]}`} onClick={handleCloseTypeSelector}/>
        </div>
    );

    return (
        <>
            <Panel header={isChartTypeSelectorOpen ? chartTypeSelectHeader : header}>
                {isChartTypeSelectorOpen &&
                <ChartTypeSelector chartTypes={def.chartTypes} onSetType={handleSetChartType}/>
                }
                <div className={`${styles.content} ${isChartTypeSelectorOpen ? styles.hidden : ""}`}>
                    <ChartLoader chartType={chartType} aggregation={def} location={location}
                                 time={time} />
                </div>
            </Panel>
        </>
    );
}