import React, {useContext, useCallback, useMemo} from "react";
import AuthContext from "../contexts/AuthContext";
import LocationContext from "../contexts/LocationContext";
import SidebarList from "./SidebarList";
import Auth from "@aws-amplify/auth";
import styles from "./Sidebar.module.css";
import {CSSTransition} from 'react-transition-group';

export default function Sidebar({isOpen, onRequestClose, location, onSetLocation}) {
    const user = useContext(AuthContext);
    const loc = useContext(LocationContext);

    const handleSignOutClick = useCallback(() => {
        window.localStorage.clear();
        Auth.signOut()
    }, []);
    const locations = useMemo(() => loc.flat.map(l => ({key: l.locationId, text: l.name, indent: l.level > 0})), [loc]);

    return (
        <>
            <CSSTransition classNames={styles} timeout={250} in={isOpen} mountOnEnter unmountOnExit>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <div>{user.username}</div>
                            <button onClick={handleSignOutClick}>Sign Out</button>
                        </div>
                        <div className={styles.lists}>
                            <SidebarList title={loc.root.name} items={locations} selected={location}
                                         onClickItem={onSetLocation}/>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition classNames={styles} timeout={250} in={isOpen} mountOnEnter unmountOnExit>
                <div className={styles.backdrop} onClick={onRequestClose}/>
            </CSSTransition>
        </>
    );
}