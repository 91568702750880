import React, {useState, useMemo, useCallback} from "react";
import DropDown from "./DropDown";
import styles from "./TimeSelector.module.css";

export default function TimeSelector({options, selected, onSetTime}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleClickTime = useCallback(e => {
        onSetTime(e.target.value);
        setIsOpen(false);
    }, [onSetTime]);
    
    const timeList = useMemo(() => options.map(({name}) => (
        <button key={name} value={name} className={selected === name ? styles.active : ""}
                onClick={handleClickTime}>{name}</button>
    )), [options, selected, handleClickTime]);

    return (
        <DropDown activator={<div className={styles.title} onClick={handleOpen}>{selected}</div>}
                  onRequestClose={handleClose} isOpen={isOpen} offsetX="11px"
                  offsetY="16px">
            <div className={styles.timeList}>
                {timeList}
            </div>
        </DropDown>
    );
}