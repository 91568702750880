import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "typeface-roboto";
import "react-dates/initialize";
import "./styles/global.css";
import "./styles/datepicker.css";

(async function() {
    // Retrieve app configuration from the server before starting up.
    const config = await (await fetch("/api/config")).json();
    
    // Set document title to distinguish non-prod environment.
    if (config["envName"])
        document.title = `[${config["envName"].toUpperCase()}] ${document.title}`;
    console.log("Server version: " + config.version);
    
    // Render the app.
    ReactDOM.render(
        <App config={config} />,
        document.getElementById('root')
    );
})();
