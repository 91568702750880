import React, { useCallback } from "react";
import styles from "./ModeSelector.module.css";
import { Link, useRouteMatch } from "react-router-dom";


export default function ModeSelector() {
    let match = useRouteMatch();

    return (
        <div className={styles.modes}>
            <Link to={`/`}>
                <button
                    type="button"
                    className={match.url === "/" ? styles.active : ""}
                >
                    Overview
                </button>
            </Link>

            <Link to={`/pours-per-minute`}>
                <button
                    type="button"
                    className={
                        match.url === "/pours-per-minute" ? styles.active : ""
                    }
                >
                    Pours Per Minute
                </button>
            </Link>

            <Link to={`/live-view`}>
                <button
                    type="button"
                    className={match.url === "/live-view" ? styles.active : ""}
                >
                    Live View
                </button>
            </Link>
            <Link to={`/reports`}>
                <button
                    type="button"
                    className={match.url === "/reports" ? styles.active : ""}
                >
                    Reports
                </button>
            </Link>
            <Link to={`/liquors`}>
                <button
                    type="button"
                    className={match.url === "/liquors" ? styles.active : ""}
                >
                    Liquors
                </button>
            </Link>
        </div>
    );
}
