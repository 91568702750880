import React, { useEffect, useRef, useLayoutEffect } from "react";
import styles from "./DropDown.module.css";

export default function DropDown({isOpen, children, activator, offsetX, offsetY, onRequestClose}) {
    const ref = useRef();
    
    useEffect(() => {
        if (isOpen) {
            function handleClickOutside(e) {
                if (ref.current && !ref.current.contains(e.target)) {
                    onRequestClose();
                }
            }
            document.addEventListener("mousedown", handleClickOutside, true);
            return () => document.removeEventListener("mousedown", handleClickOutside, true);
        }
    }, [isOpen, onRequestClose, ref]);
    
    useLayoutEffect(() => {
        if (isOpen) {
            const el = ref.current;
            const rect = el.getBoundingClientRect();
            el.style.marginLeft = Math.min(0, window.innerWidth - (rect.right + 1)) + "px";
            el.style.marginTop = Math.min(0, window.innerHeight - (rect.bottom + 1)) + "px";
        }
    }, [isOpen, ref]);
    
    return (
        <div className={styles.root}>
            { isOpen && <div className={styles.anchor}>
                <div className={styles.container} style={{left: offsetX, top: offsetY}} ref={ref}>
                    <div className={styles.content}>
                        {children}
                    </div>
                </div>
            </div>
            }
            {activator}
        </div>
    );
}