import React, {useState, useRef} from "react";
import Tooltip from "../Tooltip";
import CrossHair from "../CrossHair";
import useD3 from "../../hooks/useD3";
import useImmediate from "../../hooks/useImmediate";
import * as d3 from "d3";
import styles from "./BarChart.module.css";

const BAR_WIDTH = 12;
const MIN_LABEL_WIDTH = 35;
const MARGIN = {top: 20, right: 45, bottom: 59, left: 61};

export default function BarChart({data, width, height, detail, yAxisFormat, yDomainMin}) {
    const [tooltip, setTooltip] = useState(null);
    const tooltipRef = useRef();

    function handleMouseMove(e) {
        const t = tooltipRef.current;
        t.style.left = `${e.clientX}px`;
        t.style.bottom = `${window.innerHeight - e.clientY + 30}px`;
    }

    function handleMouseLeave(e) {
        setTooltip(null);
    }

    const animate = useImmediate(() => true, [data]);

    const ref = useD3((g) => {
        const {categories, series} = data;
        if (!series)
            return;

        function handleMouseEnter(e) {
            if (detail) {
                const d = d3.select(this).datum();
                setTooltip(detail(d, categories));
                handleMouseMove(e);
            }
        }

        const xScale = d3.scaleBand()
            .domain(series.map(i => i.x))
            .range([MARGIN.left, width - MARGIN.right]);
        xScale.paddingInner(Math.max(0.05, (xScale.bandwidth() - (BAR_WIDTH * categories.length)) / xScale.bandwidth()));
        const yScale = d3.scaleLinear()
            .domain([0, Math.max(yDomainMin ?? 10, d3.max(series, d => d3.max(d.y)))])
            .range([height - MARGIN.bottom, MARGIN.top]).nice();
        const zScale = d3.scaleBand()
            .domain(categories)
            .rangeRound([0, xScale.bandwidth()]);
        const cScale = d3.scaleOrdinal()
            .domain(categories)
            .range([1, 2, 3, 4, 5, 6]);

        g.append("g").attr("class", "plot")
            .selectAll("g")
            .data(series)
            .enter()
            .append("g")
            .attr("transform", d => `translate(${xScale(d.x)},0)`)
            .selectAll("rect")
            .data(d => d.y.map((y, i) => ({index: i, data: d, x: d.x, z: categories[i], y})))
            .enter()
            .append("rect")
            .attr("x", d => zScale(d.z))
            .attr("y", yScale(0))
            .attr("width", zScale.bandwidth())
            .attr("height", (height - MARGIN.bottom) - yScale(0))
            .attr("fill", d => `url(#bar_fill${cScale(d.z)})`)
            .on("mouseenter", handleMouseEnter)
            .on("mousemove", handleMouseMove)
            .on("mouseleave", handleMouseLeave);

        let sel = g.selectAll("rect");
        if (animate) sel = sel.transition().delay((d, i) => i * 50 / categories.length).duration(500);
        sel.attr("y", d => yScale(d.y))
            .attr("height", d => (height - MARGIN.bottom) - yScale(d.y));

        const text = g.append("g").attr("class", "xAxis")
            .attr("transform", `translate(0, ${height - MARGIN.bottom + 16})`)
            .style("font-size", xScale.step() < MIN_LABEL_WIDTH ? "10px" : "12px")
            .call(d3.axisBottom(xScale)
                .tickSize(0)
                .tickPadding(0)
            )
            .selectAll("text");
        if (xScale.step() < MIN_LABEL_WIDTH)
            text.style("text-anchor", "end").attr("transform", "rotate(-65)");
        g.select(".xAxis").select(".domain").remove();

        g.append("g").attr("class", "yAxis")
            .attr("transform", `translate(${MARGIN.left - 20}, 0)`)
            .call(d3.axisLeft(yScale)
                .ticks(Math.floor(height / 45), yAxisFormat)
                .tickSize(0)
                .tickPadding(0)
            );
        g.select(".yAxis").select(".domain").remove();
    }, [data, width, height]);

    return (
        <>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <defs>
                    <linearGradient id="bar_fill1" x1="0" y1="0" x2="0.4" y2="1">
                        <stop offset="16%" stopColor="#29F9ED" stopOpacity={1}/>
                        <stop offset="83%" stopColor="#898EFF" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bar_fill2" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#FB9FEC" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#DA0AB9" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bar_fill3" x1="0" y1="0" x2="0.4" y2="1">
                        <stop offset="16%" stopColor="#EEFF89" stopOpacity={1}/>
                        <stop offset="83%" stopColor="#F99929" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bar_fill4" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#5CFFC4" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#39FF4D" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bar_fill5" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#E99E59" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#FF2727" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bar_fill6" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#0094FF" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#4655E0" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <g ref={ref} className={styles.chart}>
                </g>
                <CrossHair width={width} height={height} margin={MARGIN}/>
            </svg>
            <Tooltip content={tooltip} ref={tooltipRef}/>
        </>
    );
}
