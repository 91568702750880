import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import Auth from "@aws-amplify/auth";
import styles from "./LoginPage.module.css";
import SkyfloLogo from "../components/SkyfloLogo";
import introVideo from "../videos/intro.mp4";
import { CSSTransition } from 'react-transition-group';

const FADE_IN_TIME = 5.1;

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [working, setWorking] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const video = useRef();

    const isValid = !!username && !!password;

    const handleSubmit = useCallback(e => {
        (async function () {
            e.preventDefault();
            if (!isValid)
                return;
            setError(null);
            setWorking(true);
            try {
                const signIn = await Auth.signIn(username, password);
                var user;
                if (signIn.challengeName === "NEW_PASSWORD_REQUIRED") {
                    user = await Auth.completeNewPassword(signIn, password, {});
                } else {
                    user = await Auth.currentAuthenticatedUser();
                }
                if (!user.signInUserSession) {
                    setWorking(false);
                    setError("Your account is not configured correctly. Please contact your administrator.");
                }
            }
            catch (e) {
                setWorking(false);
                if (e.message) {
                    setError(e.message);
                }
                else {
                    setError("An unexpected error occurred.");
                    console.error(e);
                }
            }
        })();
    }, [isValid, password, username]);

    useLayoutEffect(() => {
        const vid = video.current;
        let frame = requestAnimationFrame(tick);
        function tick() {
            frame = requestAnimationFrame(tick);
            if (vid.currentTime >= FADE_IN_TIME) {
                setShowLoginForm(true);
                cancelAnimationFrame(frame);
            }
        }
        return () => cancelAnimationFrame(frame);
    }, []);

    return (
        <div className={`page ${styles.page}`}>
            <div className={styles.video}>
                <video ref={video} src={introVideo} muted autoPlay preload="auto" playsInline />
            </div>
            <div className={styles.fontPreload}>
                000
            </div>
            <CSSTransition classNames={styles} timeout={1000} in={showLoginForm} mountOnEnter unmountOnExit>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.header}>
                        <SkyfloLogo className={styles.logo} />
                    </div>
                    <div className={styles.field}>
                        <input type="text" maxLength={255} value={username} placeholder="Username"
                            onChange={e => setUsername(e.target.value)} autoFocus />
                    </div>
                    <div className={styles.field}>
                        <input type="password" maxLength={255} value={password} placeholder="Password"
                            onChange={e => setPassword(e.target.value)} />
                    </div>
                    {error &&
                        <div className={styles.error}>
                            {error}
                        </div>
                    }
                    <div className={styles.actions}>
                        <div className="action">
                            <button type="submit" disabled={!isValid || working}>Log In</button>
                        </div>
                    </div>
                </form>
            </CSSTransition>
        </div>
    );
}
