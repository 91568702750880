import { useState, useEffect, useContext, useMemo } from "react";
import AuthContext from "../contexts/AuthContext";

export default function useStorageState(key, defaultValue) {
    const user = useContext(AuthContext);
    
    const scopedKey = useMemo(() => `${user.username}::${key}`, [user, key]);
    
    const [value, setValue] = useState(() => {
        const storedValue = window.localStorage.getItem(scopedKey);
        return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    }, [user]);
    
    useEffect(() => window.localStorage.setItem(scopedKey, JSON.stringify(value)), [scopedKey, value]);
    return [value, setValue];
}
