import React, {useState, useRef} from "react";
import Tooltip from "../Tooltip";
import CrossHair from "../CrossHair";
import useD3 from "../../hooks/useD3";
import useImmediate from "../../hooks/useImmediate";
import * as d3 from "d3";
import styles from "./ScatterChart.module.css";

const DOT_RADIUS = 6;
const MIN_LABEL_WIDTH = 35;
const MARGIN = {top: 20, right: 87, bottom: 125, left: 97};
const PADDING = {top: 0, right: 47, bottom: 32, left: 47};
const LEGEND_SPACING = 89;
const LEGEND_PADDING = 88;

export default function ScatterChart({data, width, height, detail, yAxisFormat, yDomainMin}) {
    const [tooltip, setTooltip] = useState(null);
    const tooltipRef = useRef();

    function handleMouseMove(e) {
        const t = tooltipRef.current;
        t.style.left = `${e.clientX}px`;
        t.style.bottom = `${window.innerHeight - e.clientY + 30}px`;
    }

    function handleMouseLeave(e) {
        setTooltip(null);
    }
    
    const animate = useImmediate(() => true, [data]);

    const ref = useD3((g) => {
        const {categories, series} = data;
        if (!series)
            return;

        function handleMouseEnter(e) {
            if (detail) {
                const d = d3.select(this).datum();
                setTooltip(detail(d, categories));
                handleMouseMove(e);
            }
        }

        const xScale = d3.scalePoint()
            .domain(series.map(i => i.x))
            .range([MARGIN.left, width - MARGIN.right]);
        const yScale = d3.scaleLinear()
            .domain([0, Math.max(yDomainMin ?? 10, d3.max(series, d => d3.max(d.y)))])
            .range([height - MARGIN.bottom, MARGIN.top]).nice();
        const cScale = d3.scaleOrdinal()
            .domain(categories)
            .range(["#058FFD", "#3FFF5F", "#EC8C51", "#29F9ED", "#F585E3", "#EFFD86"]);
        const mean = d3.mean(series.flatMap(d => d.y), d => d);

        g.append("g").attr("class", "plot")
            .selectAll("g")
            .data(series)
            .enter()
            .append("g")
            .selectAll("circle")
            .data(d => d.y.map((y, i) => ({index: i, data: d, x: d.x, z: categories[i], y})))
            .enter()
            .append("circle")
            .attr("cx", d => xScale(d.x))
            .attr("cy", d => yScale(mean))
            .attr("r", 0)
            .attr("fill", d => cScale(d.z))
            .on("mouseenter", handleMouseEnter)
            .on("mousemove", handleMouseMove)
            .on("mouseleave", handleMouseLeave);
        
        let sel = g.selectAll(".plot circle");
        if (animate) sel = sel.transition().delay((d, i) => i * 50).duration(500);
        sel.attr("cy", d => yScale(d.y))
            .attr("r", DOT_RADIUS);

        sel = g.append("line")
            .attr("stroke", "white")
            .attr("strokeWidth", 1)
            .attr("x1", MARGIN.left)
            .attr("x2", MARGIN.left)
            .attr("y1", yScale(mean))
            .attr("y2", yScale(mean));
        if (animate) sel = sel.transition().duration(500 + series.length * 50);
        sel.attr("x2", width - MARGIN.right);

        const text = g.append("g").attr("class", "xAxis")
            .attr("transform", `translate(0, ${height - MARGIN.bottom + PADDING.bottom})`)
            .style("font-size", xScale.step() < MIN_LABEL_WIDTH ? "10px" : "12px")
            .call(d3.axisBottom(xScale)
                .tickSize(0)
                .tickPadding(0)
            )
            .selectAll("text");
        if (xScale.step() < MIN_LABEL_WIDTH)
            text.style("text-anchor", "end").attr("transform", "rotate(-65)");
        g.select(".xAxis").select(".domain").remove();

        g.append("g").attr("class", "yAxis")
            .attr("transform", `translate(${MARGIN.left - PADDING.left}, 0)`)
            .call(d3.axisLeft(yScale)
                .ticks(Math.max(10, Math.min(Math.ceil(yScale.domain()[1]), Math.floor(height / 45))), yAxisFormat)
                .tickSize(0)
                .tickPadding(0)
            );
        g.select(".yAxis").select(".domain").remove();

        const legendX = MARGIN.left + (width - MARGIN.left - MARGIN.right - (LEGEND_SPACING * (categories.length + 1))) / 2;
        const legend = g.append("g").attr("class", styles.legend)
            .attr("transform", `translate(${legendX},${height - MARGIN.bottom + LEGEND_PADDING})`);

        const meanLabel = legend.append("g");
        meanLabel.append("line")
            .attr("x1", 0)
            .attr("x2", 14)
            .attr("y1", 0)
            .attr("y2", 0)
            .attr("stroke", "white")
            .attr("strokeWidth", 1);
        meanLabel.append("text")
            .attr("x", 19)
            .attr("y", 0)
            .attr("dominant-baseline", "central")
            .text("Average");

        const label = legend.append("g").selectAll("g")
            .data(categories)
            .enter()
            .append("g")
            .attr("transform", (d, i) => `translate(${(i + 1) * LEGEND_SPACING}, 0)`);
        label.append("circle")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", DOT_RADIUS)
            .attr("fill", d => cScale(d))
        label.append("text")
            .attr("x", DOT_RADIUS + 10)
            .attr("y", 0)
            .attr("width", LEGEND_SPACING - DOT_RADIUS * 2 - 10)
            .attr("dominant-baseline", "central")
            .text(d => d)
            .each(wrap);

        function wrap() {
            const self = d3.select(this);
            let textLength = self.node().getComputedTextLength(),
                text = self.text();
            while ((textLength > self.attr('width')) && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + '...');
                textLength = self.node().getComputedTextLength();
            }
        }
    }, [data, width, height]);

    return (
        <>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <g ref={ref} className={styles.chart}>
                </g>
                <CrossHair width={width} height={height} margin={MARGIN} />
            </svg>
            <Tooltip content={tooltip} ref={tooltipRef}/>
        </>
    );
}
