const hoveredStyles = {
    background: "linear-gradient(0deg, rgba(255, 0, 245,0) 25%, rgba(255, 0, 245,0.3) 40%, rgba(0, 71, 255,0.3) 70%, rgba(0, 71, 255,0) 75%)",
    border: "1px",
    color: "#FFF",
    hover: {
        background: "linear-gradient(0deg, rgba(255, 0, 245,0) 25%, rgba(255, 0, 245,0.3) 40%, rgba(0, 71, 255,0.3) 70%, rgba(0, 71, 255,0) 75%)",
        border: "1px",
        borderRadius: "50%",
        color: "#FFF"
    }
};

const selectedStyles = {
    background: "linear-gradient(0deg, rgba(255, 0, 245,0) 25%, rgba(255, 0, 245,0.3) 40%, rgba(0, 71, 255,0.3) 70%, rgba(0, 71, 255,0) 75%)",
    color: "#DDD",

    hover: {
        background: "linear-gradient(0deg, rgba(255, 0, 245,0) 25%, rgba(255, 0, 245,0.3) 40%, rgba(0, 71, 255,0.3) 70%, rgba(0, 71, 255,0) 75%)",
        border: "1px",
        borderRadius: "50%",
        color: "#FFF"
    }
};

const selectedStartStyles = {
    background: "linear-gradient(0deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
    border: "12px",
    borderRadius: "50%",
    color: "#FFF",

    hover: {
        background: "linear-gradient(0deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
        border: "1px",
        borderRadius: "50%",
        color: "#FFF"
    }
}

const selectedEndStyles = {
    background: "linear-gradient(0deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
    border: "1px",
    borderRadius: "50%",
    color: "#FFF",

    hover: {
        background: "linear-gradient(0deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
        border: "1px",
        borderRadius: "50%",
        color: "#FFF"
    }
}

const selectedSpanStyles = {
    background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
    // border: "1px solid #ff5a5f",
    color: "#FFF",

    hover: {
        background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
        // border: "1px solid #bc6e70",
        color: "#FFF"
    }
};

// const hoveredSpanStyles = {
//     background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
//     // border: "1px solid #ff5a5f",
//     color: "#FFF",
//
//     hover: {
//         background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
//         // border: "1px solid #bc6e70",
//         color: "#FFF"
//     }
// };
//
// const afterHoveredStartStyles = {
//     background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
//     // border: "1px solid #ff5a5f",
//     color: "#FFF",
//
//     hover: {
//         background: "linear-gradient(331.46deg, rgba(255, 0, 245, 0.3) 17.46%, rgba(0, 71, 255, 0.294) 87.82%)",
//         // border: "1px solid #bc6e70",
//         color: "#FFF"
//     }
// }

const defaultStyles = {
    background: "rgba(0,0,0,0)",
}

// const renderWeekHeaderElement = {
//     background: "rgba(0,0,0,1)",
//     color: "#FF0000",
// }

export const customDayStyles = {
    defaultStyles: defaultStyles,
    selectedStartStyles: selectedStartStyles,
    selectedEndStyles: selectedStartStyles,
    selectedSpanStyles: selectedSpanStyles,
    hoveredSpanStyles: hoveredStyles,
    //hoveredStyles: hoveredStyles,
    afterHoveredStartStyles: selectedEndStyles,
    selectedStyles: selectedStyles,
    hoveredStartFirstPossibleEndStyles: selectedStartStyles,
    //renderWeekHeaderElement: renderWeekHeaderElement
};

export const controlStyle = {
    display: "block",
    background: "rgba(0,0,0,0)",
    textAlign: "center"
};

export const roundedButtonStyle = {
    display:'inline-block',
    padding: '0.6em 3.5em',
    margin: '0 0.3em 0.9em 0',
    borderRadius: '2em',
    boxSizing: 'border-box',
    fontFamily:'Roboto',
    fontWeight: '400',
    color: '#FFFFFF',
    background: "linear-gradient(101.12deg, #1D314F 0%, #102749 100%)",
    textAlign: 'center',
    boxShadow: '5px 5px 50px 0px rgba(0, 0, 0, 0.6)',
};

export const CustomNavPrev = () => (
    <div
        style={{
            // border: '1px solid #dce0e0',
            background: 'rgba(0,0,0,0)',
            textAlign: 'left',
            color: '#FFFFFF',
            left: '22px',
            padding: '1px',
            position: 'absolute',
            top: '20px',
            width: '40%',
            height: '30px'
        }}
        tabIndex="0"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
        </svg>
    </div>
);

export const CustomNavNext = () => (
    <div
        style={{
            // border: '1px solid #dce0e0',
            background: 'rgba(0,0,0,0)',
            textAlign: 'right',
            color: '#FFFFFF',
            padding: '1px',
            position: 'absolute',
            right: '22px',
            top: '20px',
            width: '40%',
            height: '30px',
        }}
        tabIndex="0"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
        </svg>
    </div>
);
