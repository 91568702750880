import {useEffect, useRef} from "react";
import * as d3 from "d3";

export default function useD3(fn, deps) {
    const ref = useRef();

    useEffect(() => {
            const root = d3.select(ref.current);
            root.selectAll("*").remove();
            fn(root);
            return () => {
            };
        },
        // eslint-disable-next-line
        [...deps]);
    return ref;
}
