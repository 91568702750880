import React from "react";
import styles from "./Header.module.css";
import SkyfloLogo from "./SkyfloLogo";
import ModeSelector from "./ModeSelector";
import { Link } from "react-router-dom";

export default function Header({env, onClickSettings}) {
    return (
        <div className={styles.header}>
            <button className={styles.settingsButton} onClick={onClickSettings}/>
            <Link to={`/`}><SkyfloLogo className={styles.logo}/></Link>
            <div className={styles.env}>{env ? `[ ${env.toUpperCase()} ENVIRONMENT ]` : ""}</div>
            <ModeSelector />
        </div>
    );
}