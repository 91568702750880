import React, {useState, useRef, useEffect} from "react";

export default function CrossHair({margin, width, height}) {
    const [pos, setPos] = useState(null);
    const g = useRef();

    useEffect(() => {
        function handleMouseMove(e) {
            const rect = svg.getBoundingClientRect();
            const pos = {x: e.clientX - rect.left, y: e.clientY - rect.top};
            setPos(pos);
            return true;
        }

        function handleMouseLeave(e) {
            setPos(null);
            return true;
        }

        const svg = g.current.parentNode;
        svg.addEventListener("mouseenter", handleMouseMove);
        svg.addEventListener("mousemove", handleMouseMove);
        svg.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            svg.removeEventListener("mouseenter", handleMouseMove);
            svg.removeEventListener("mousemove", handleMouseMove);
            svg.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    return (
        <g ref={g} pointerEvents="none">
            <defs>
                <linearGradient id="crosshair_gradient" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="17%" stopColor="rgb(0, 71, 255)" stopOpacity={0.59}/>
                    <stop offset="88%" stopColor="rgb(255, 0, 245)" stopOpacity={0.6}/>
                </linearGradient>
            </defs>
            {!!pos && pos.x >= margin.left && pos.x <= width - margin.right &&
                pos.y >= margin.top && pos.y <= height - margin.bottom &&
            <g>
                <g>
                    <line stroke="#e7e7e7" strokeWidth={1}
                          x1={margin.left} y1={pos.y+0.5} x2={width - margin.right} y2={pos.y+0.5}/>
                    <line stroke="#e7e7e7" strokeWidth={1}
                          x1={pos.x+0.5} y1={margin.top} x2={pos.x+0.5} y2={height - margin.bottom}/>
                </g>
                <g transform={`translate(${pos.x},${pos.y})`}>
                    <circle cx="0" cy="0" r="8" fill="#00d1ff"/>
                    <circle cx="0" cy="0" r="6" fill="url(#crosshair_gradient)"/>
                </g>
            </g>
            }
        </g>
    );
}
