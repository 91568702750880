import React, {useContext, useState, useCallback} from "react";
import LocationContext from "../contexts/LocationContext";
import styles from "./PoursPerMinutePage.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import PanelLayout from "../components/PanelLayout";
import ReportDialog from "../components/ReportDialog";
import useStorageState from "../hooks/useStorageState";

export default function PoursPerMinutePage({config}) {
    const loc = useContext(LocationContext);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [location, setLocation] = useStorageState("location", loc.flat[0].locationId);

    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const handleReportDialogClose = useCallback(() => setIsReportDialogOpen(false), []);

    const handleOpenSettings = useCallback(() => setIsSettingsOpen(true), []);
    const handleCloseSettings = useCallback(() => setIsSettingsOpen(false), []);
    const handleSetLocation = useCallback(v => setLocation(v), [setLocation]);
    const handleDownloadReport = useCallback(() => setIsReportDialogOpen(true), []);

    return (
        <>
            <div className={styles.page}>
                <Header 
                        onClickSettings={handleOpenSettings} env={config.envName}/>
                <PanelLayout mode={"pours"} location={location}/>
            </div>
            <Sidebar isOpen={isSettingsOpen} onRequestClose={handleCloseSettings} onSetLocation={handleSetLocation}
                     location={location}/>
            <ReportDialog isOpen={isReportDialogOpen} location={location} onRequestClose={handleReportDialogClose}/>
        </>
    );
}
