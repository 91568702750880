const aggregations = {
    overview: {
        main: [
            {
                key: "barSales",
                url: "/api/bar-sales",
                text: "Bar Pours",
                refresh: 60,
                time: [
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 10 days", start: d => d.startOf("day").subtract(10, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Last 10 days",
                defaultChartType: "area:smooth",
                maximizedChartType: "scatter",
                chartTypes: ["bar", "area:smooth", "area"],
                chartProps: {
                    detail: (d, c) => ({
                        header: (!! c && c.length > 1) ? d.z : d.x,
                        metrics: [
                            {name: "Complete Pours", value: d.y}
                        ]
                    })
                }
            },
            {
                key: "salesByItem",
                url: "/api/sales-by-item",
                text: "Pours By Item",
                refresh: 60,
                time: [
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 10 days", start: d => d.startOf("day").subtract(10, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Last 10 days",
                defaultChartType: "stackedBar",
                chartTypes: ["stackedBar"],
                chartProps: {
                    normalize: true,
                    yAxisFormat: "%",
                    detail: (d, d1, c) => ({
                        header: c[d1.key],
                        metrics: [
                            {name: "Complete Pours", value: d.data.y[d1.key]}
                        ]
                    })
                }
            },
            {
                key: "mispoursTracking",
                url: "/api/mispours-tracking",
                text: "Incomplete Pours Tracking",
                refresh: 60,
                time: [
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 11 days", start: d => d.startOf("day").subtract(11, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Last 11 days",
                defaultChartType: "bar",
                maximizedChartType: "scatter",
                chartTypes: ["bar", "area:smooth", "area"],
                chartProps: {
                    yAxisFormat: "%",
                    yDomainMin: 0.1,
                    detail: (d, c) => {
                        if (d.data) {
                            return {
                                header: !!c ? (c.length > 1 ? d.z : d.x) : null,
                                metrics: [
                                    {name: "Complete Pours", value: d.data.a[d.index]},
                                    {name: "Incomplete Pours", value: d.data.b[d.index]},
                                    {name: "Total", value: d.data.a[d.index] + d.data.b[d.index]},
                                    {name: "% Incomplete", value: `${Math.round(d.y*100)}%`, style: "alert"}
                                ]
                            };
                        }
                        else {
                            return {
                                header: d.x,
                                metrics: [
                                    {name: "Complete Pours", value: d.a},
                                    {name: "Incomplete Pours", value: d.b},
                                    {name: "Total", value: d.a + d.b},
                                    {name: "% Incomplete", value: `${Math.round(d.y*100)}%`, style: "alert"}
                                ]
                            }
                        }
                    }
                }
            },
        ],
        aux: [
            {
                key: "salesBreakdown",
                url: "/api/sales-breakdown",
                text: "Pours Breakdown",
                refresh: 60,
                time: [
                    {name: "Today", start: d => d.startOf("day")},
                    {name: "This week", start: d => d.startOf("week")},
                    {name: "This month", start: d => d.startOf("month")},
                    {name: "Last hour", start: d => d.subtract(1, "hours")},
                    {name: "Last 24 hours", start: d => d.subtract(1, "days")},
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Today",
                defaultChartType: "pie",
                chartProps: {
                    detail: d => ({
                        header: d.data.label,
                        metrics: [
                            {name: "Complete Pours", value: d.data.value}
                        ]
                    })
                }
            },
            {
                key: "mispours",
                url: "/api/mispours",
                text: "Incomplete Pours",
                refresh: 60,
                time: [
                    {name: "Today", start: d => d.startOf("day")},
                    {name: "This week", start: d => d.startOf("week")},
                    {name: "This month", start: d => d.startOf("month")},
                    {name: "Last hour", start: d => d.subtract(1, "hours")},
                    {name: "Last 24 hours", start: d => d.subtract(1, "days")},
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Today",
                defaultChartType: "bottle",
                chartProps: {
                    detail: d => ({
                        metrics: [
                            {name: "Complete Pours", value: d.sales},
                            {name: "Complete Vol", value: d.successVolume},
                            {name: "Incomplete Pours", value: d.mispours},
                            {name: "Incomplete Vol", value: d.mispourVolume},
                            {name: "% Incomplete Vol", value: `${Math.round(d.percentageMispours * 100)}%`, style: "alert"}
                        ]
                    })
                }
            }
        ]
    },
    pours: {
        main: [
            {
                key: "minuteTracking",
                url: "/api/minute-tracking",
                text: "Minute Tracking",
                refresh: 30,
                time: [
                    {name: "Last 30 minutes", start: d => d.subtract(29, "minutes")},
                    {name: "Last 60 minutes", start: d => d.subtract(59, "minutes")},
                ],
                defaultTime: "Last 30 minutes",
                defaultChartType: "bar",
                chartTypes: ["bar", "area:smooth", "area"],
                chartProps: {
                    detail: (d, c) => ({
                        header: (c && c.length > 1) ? d.z : `${d.x} min`,
                        metrics: [
                            {name: "Complete Pours", value: d.y}
                        ]
                    })
                }
            },
            {
                key: "ppmHistory",
                url: "/api/ppm-history",
                text: "PPM History",
                refresh: 60,
                time: [
                    {name: "Last 7 days", start: d => d.startOf("day").subtract(7, "days")},
                    {name: "Last 10 days", start: d => d.startOf("day").subtract(10, "days")},
                    {name: "Last 14 days", start: d => d.startOf("day").subtract(14, "days")},
                    {name: "Last 30 days", start: d => d.startOf("day").subtract(30, "days")}
                ],
                defaultTime: "Last 10 days",
                defaultChartType: "area:smooth",
                maximizedChartType: "scatter",
                chartTypes: ["bar", "area:smooth", "area"],
                chartProps: {
                    yDomainMin: 5,
                    detail: (d, c) => ({
                        header: (!!c && c.length > 1) ? d.z : d.x,
                        metrics: [
                            {name: "PPM (90th %tile)", value: d.y}
                        ]
                    })
                }
            }
        ],
        aux: [
            {
                key: "poursPerMinute",
                url: "/api/pours-per-minute",
                text: "Pours Per Minute",
                refresh: 5,
                time: [
                    {name: "Last 30 minutes", start: d => d.subtract(59, "minutes")}
                ],
                defaultTime: "Last 30 minutes",
                defaultChartType: "ppm"
            }
        ]
    }
};

export default aggregations;