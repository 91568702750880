import React from "react";
import styles from "./Panel.module.css";

export default function Panel({header, children, title}) {
    return (
        <div className={styles.panelOuter}>
            <div className={styles.panelInner}>
                <div>
                    {title}
                    {header}
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
}