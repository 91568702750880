import React, {useState, useMemo, useRef} from "react";
import useD3 from "../../hooks/useD3";
import useImmediate from "../../hooks/useImmediate";
import * as d3 from "d3";
import styles from "./AreaChart.module.css";
import Tooltip from "../Tooltip";
import CrossHair from "../CrossHair";

let uniqueId = 0;

const MARGIN = {top: 20, right: 72, bottom: 76, left: 67};
const PADDING = {bottom: 30, left: 30};
const MIN_LABEL_WIDTH = 35;

export default function AreaChart({data, width, height, smooth, detail, yAxisFormat, yDomainMin}) {
    const [tooltip, setTooltip] = useState(null);
    const tooltipRef = useRef();

    const [clipId] = useState(() => "area_clip_" + uniqueId++);

    // Our area chart only supports a single series/line per the design, so we aggregate all the categories here.
    const series = useMemo(() => {
        const {series} = data;
        if (!series)
            return [];
        return series.map(s => ({
            x: s.x,
            y: d3.sum(s.y),
            a: s.a ? d3.sum(s.a) : null,
            b: s.b ? d3.sum(s.b) : null
        }));
    }, [data]);

    function handleMouseMove(e) {
        const t = tooltipRef.current;
        t.style.left = `${e.clientX}px`;
        t.style.bottom = `${window.innerHeight - e.clientY + 30}px`;
    }

    function handleMouseLeave(e) {
        setTooltip(null);
    }

    const animate = useImmediate(() => true, [data]);

    const ref = useD3((g) => {
        function handleMouseEnter(e) {
            if (detail) {
                const d = d3.select(this).datum();
                setTooltip(detail(d));
                handleMouseMove(e);
            }
        }
        
        const xScale = d3.scalePoint()
            .domain(series.map(i => i.x))
            .range([MARGIN.left, width - MARGIN.right])
            .padding(0);
        const yScale = d3.scaleLinear()
            .domain([0, Math.max(yDomainMin ?? 10, d3.max(series, d => d.y))])
            .range([height - MARGIN.bottom, MARGIN.top]).nice();

        g.append("defs")
            .append("clipPath")
            .attr("id", clipId)
            .append("rect")
            .attr("x", MARGIN.left)
            .attr("y", 0)
            .attr("width", width - MARGIN.left - MARGIN.right)
            .attr("height", height);

        const plot = g.append("g").attr("class", "plot").datum(series)
            .attr("clip-path", `url(#${clipId})`);
        const curve = smooth ? d3.curveMonotoneX : d3.curveLinear;

        plot.append("path")
            .attr("class", "underArea")
            .attr("d", d3.area()
                .x(d => xScale(d.x))
                .y0(height - MARGIN.bottom)
                .y1(d => yScale(d.y))
                .curve(curve)
            );

        let sel = plot.append("path")
            .attr("class", "overArea")
            .attr("d", d3.area()
                .x(d => xScale(d.x))
                .y0(height - MARGIN.bottom)
                .y1(MARGIN.top)
                .curve(curve)
            );
        if (animate) sel = sel.transition().duration(500);
        sel.attr("d", d3.area()
            .x(d => xScale(d.x))
            .y0(d => yScale(d.y))
            .y1(MARGIN.top)
            .curve(curve));

        sel = plot.append("path")
            .attr("class", "line")
            .attr("d", d3.line()
                .x(d => xScale(d.x))
                .y(height - MARGIN.bottom)
                .curve(curve)
            );
        if (animate) sel = sel.transition().duration(500);
        sel.attr("d", d3.line()
            .x(d => xScale(d.x))
            .y(d => yScale(d.y))
            .curve(curve));
        
        g.append("g").attr("class", "tooltips")
            .selectAll("circle")
            .data(series)
            .enter()
            .append("circle")
            .attr("cx", d => xScale(d.x))
            .attr("cy", d => yScale(d.y))
            .attr("r", xScale.step() / 3)
            .attr("fill", "transparent")
            .on("mouseenter", handleMouseEnter)
            .on("mousemove", handleMouseMove)
            .on("mouseleave", handleMouseLeave);
        
        const text = g.append("g").attr("class", "xAxis")
            .attr("transform", `translate(0, ${height - MARGIN.bottom + PADDING.bottom})`)
            .style("font-size", xScale.step() < MIN_LABEL_WIDTH ? "10px" : "12px")
            .call(d3.axisBottom(xScale)
                .tickSize(0)
                .tickPadding(0)
            )
            .selectAll("text");
        if (xScale.step() < MIN_LABEL_WIDTH)
            text.style("text-anchor", "end").attr("transform", "rotate(-65)");
        g.select(".xAxis").select(".domain").remove();

        g.append("g").attr("class", "yAxis")
            .attr("transform", `translate(${MARGIN.left - PADDING.left}, 0)`)
            .call(d3.axisLeft(yScale)
                .ticks(Math.floor(height / 45), yAxisFormat)
                .tickSize(0)
                .tickPadding(0)
            );
        g.select(".yAxis").select(".domain").remove();
    }, [series, width, height, smooth]);

    return (
        <>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <defs>
                    <linearGradient id="area_underFill" x1="0" y1="0" x2="0.5" y2="1">
                        <stop offset="33%" stopColor="#000000" stopOpacity={0.6}/>
                        <stop offset="78%" stopColor="#000000" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="area_lineStroke" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor="#898eff" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#2af2ff" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="area_overFill" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#182D4D" stopOpacity={1}/>
                        <stop offset="63%" stopColor="#254C6E" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <g ref={ref} className={styles.chart}>
                </g>
                <CrossHair width={width} height={height} margin={MARGIN} />
            </svg>
            <Tooltip content={tooltip} ref={tooltipRef}/>
        </>
    );
}