import React from "react";
import styles from "./Table.module.css";

export default function Table({ columns, rows }) {
    const tableHeaders = (
        <thead>
            <tr>
                {columns.map(function (column, index) {
                    if (column.cAlign){
                        return  <th style={{textAlign:column.cAlign}} key={index}>{column.title}</th>;
                    }
                    return <th key={index}>{column.title}</th>;
                })}
            </tr>
        </thead>
    );
    var tableBody = () => {
        if (rows.length === 0) {
            return (
                <tr>
                    <td style={{border:"none"}}>No data</td>
                </tr>
            );
        }
        return rows.map(function (row, i) {
            return (
                <tr key={i}>
                    {columns.map(function (column, j) {
                        const textAlign = column["align"] ?? "start"
                        if (column.render != null) {
                            return (
                                <td style={{textAlign: textAlign}} key={`${i}_${j}`}>
                                    {column.render(row, column, i, rows)}
                                </td>
                            );
                        }
                        if (row[column.key] == null) {
                            return <td key={`${i}_${j}`}>""</td>;
                        }
                        
                        return <td style={{textAlign: textAlign}}  key={`${i}_${j}`}>{row[column.key]}</td>;
                    })}
                </tr>
            );
        });
    };
    return (
        <table className={styles.table} width="100%">
            {tableHeaders}
            <tbody>{tableBody()}</tbody>
        </table>
    );
}
