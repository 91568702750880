import React, {forwardRef} from "react";
import styles from "./Tooltip.module.css";

export default forwardRef(function Tooltip({content}, ref) {
    return (
        <div className={styles.container} style={{display: content ? "block" : "none"}} ref={ref}>
            { content &&
            <div className={styles.content}>
                {content.header ?
                <div className={styles.header}>
                    {content.header}
                </div>
                : <div/>
                }
                <div className={styles.metrics}>
                    {content.metrics.map((m,i) => (
                        <React.Fragment key={i}>
                            <div>{m.name}:</div>
                            <div className={m.style==="alert"?styles.alert:""}>{m.value}</div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            }
        </div>
    );
});