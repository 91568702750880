import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import LocationContext from "../contexts/LocationContext";
import AuthContext from "../contexts/AuthContext";
import styles from "./LiveViewPage.module.css";
import panelStyles from "../components/PanelLayout.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import DropDown from "../components/DropDown";
import useStorageState from "../hooks/useStorageState";
import Panel from "../components/Panel";
import Table from "../components/Table";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import DateRangeSelector from "../components/DateRangeSelector";
import ApiContext from "../contexts/ApiContext";
import { saveAs } from "file-saver";

export default function LiquorConpositePage({ config }) {
    const loc = useContext(LocationContext);
    const api = useContext(ApiContext);
    const user = useContext(AuthContext);

    const [mode, setMode] = useStorageState("mode", "overview");
    const [location, setLocation] = useStorageState(
        "location",
        loc.flat[0].locationId
    );

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState(null);
    const [selectedDates, setSelectedDates] = useState([
        moment().tz(loc.byId[location].timezone ?? "UTC"),
        moment().tz(loc.byId[location].timezone ?? "UTC"),
    ]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);



    const handleOpenSettings = useCallback(() => setIsSettingsOpen(true), []);
    const handleCloseSettings = useCallback(() => setIsSettingsOpen(false), []);
    const handleSetLocation = useCallback((v) => setLocation(v), [setLocation]);

    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleClickLocation = useCallback(
        (e) => {
            handleSetLocation(e.target.value);
            setIsOpen(false);
        },
        [handleSetLocation]
    );

    const title = useMemo(() => {
        return `${loc.root.name} - ${loc.byId[location]?.name}`;
    }, [location, loc]);
    const locationList = useMemo(
        () =>
            loc.root.locations.map(({ level, locationId, name }) => (
                <button
                    key={locationId}
                    value={locationId}
                    className={`${level > 0 ? styles.indent : ""} ${location === locationId ? styles.active : ""
                        }`}
                    onClick={handleClickLocation}
                >
                    {`${loc.root.name} - ${name}`}
                </button>
            )),
        [loc, location, handleClickLocation]
    );

    const handleDownloadClick = useCallback(async () => {
        setIsDownloading(true);
        const start = selectedDates[0].startOf("d").format();
        const end = selectedDates[1].endOf("d").format();
        let username = user.username;
        let queryStringParameters = new URLSearchParams({
            user: username,
            location_id: location,
            start_date: start,
            end_date: end,
            file_type: "csv",
            date: new Date().getTime(),
        });
        let url =
            "https://86rc9nu3wg.execute-api.us-east-2.amazonaws.com/dev/report/gen4?";
        url += queryStringParameters;
        const response = await api.download(url);
        const blob = await response.blob();
        saveAs(new Blob([blob]), `report.csv`);
        setIsDownloading(false);
    }, [selectedDates]);

    useEffect(() => {
        setMode("overview");
    }, []);

    const groupBy = (items, props) => {
        let prop = props[0];
        const grouped = items.reduce((accumulator, currentValue) => {
            if (accumulator.length == 0) {
                let group = {
                    key: prop,
                    value: currentValue[prop],
                    items: [currentValue],
                };
                accumulator.push(group);
                return accumulator;
            }
            let find = accumulator.find((g) => g.value === currentValue[prop]);
            if (find) {
                find.items.push(currentValue);
            } else {
                let group = {
                    key: prop,
                    value: currentValue[prop],
                    items: [currentValue],
                };
                accumulator.push(group);
            }
            return accumulator;
        }, []);

        if (props.length > 1) {
            for (let index = 0; index < grouped.length; index++) {
                const element = grouped[index];
                const newProps = props.slice(1, props.length);
                const deepGroup = groupBy(element.items, newProps);
                element.items = deepGroup;
            }
            return grouped;
        } else {
            return grouped;
        }
    };

    useEffect(() => {
        const loadData = async function () {
            setIsLoading(true);

            const start = selectedDates[0].startOf("d").format();
            const end = selectedDates[1].endOf("d").format();
            console.log(`location ${location}`);
            let username = user.username;
            let url =
                "https://tjp7uro30h.execute-api.us-east-2.amazonaws.com/latest/api/gen4/reportByPourSelection";
            url += `?startDateTime=${start}&endDateTime=${end}&userid=${username}&locationId=${location}`;
            const newData = await api.get(url);
            let records = newData.records;
            records.forEach((record) => {
                if (record["cost"] === "") {
                    record["cost"] = 20;
                } else {
                    record["cost"] = Number(record["cost"]);
                }
                record["size"] = Number(record["size"]);
                record["setpointprice"] = Number(record["setpointprice"]);
            });

            setItems(newData.records);
            setIsLoading(false);
        };

        loadData();
    }, [selectedDates, location]);

    const tableData = {
        columns: [
            {
                title: "Liquor",
                key: "liquorname",
                align: "start",
                cAlign: "start",
                render: (row, column, index, rows) => {
                    if (index === 0) {
                        return `${row[column.key]}`;
                    }
                    if (row[column.key] !== rows[index - 1][column.key]) {
                        return `${row[column.key]}`;
                    } else {
                        return "";
                    }
                },
            },

            {
                title: "Pour Size",
                key: "size",
                align: "center",
                render: (row, column) => {
                    const size = row[column.key];
                    switch (size) {
                        case 1:
                            return (
                                <div style={{ color: "#99989A" }}>
                                    {row[column.key]}
                                </div>
                            );
                        case 2:
                            return (
                                <div style={{ color: "#EB9739" }}>
                                    {row[column.key]}
                                </div>
                            );
                        case 3:
                            return (
                                <div style={{ color: "#607E5C" }}>
                                    {row[column.key]}
                                </div>
                            );
                        case 4:
                            return (
                                <div style={{ color: "#8080FF" }}>
                                    {row[column.key]}
                                </div>
                            );
                        default:
                            return <div>{row[column.key]}</div>;
                            break;
                    }
                },
            },
            {
                title: "Volume (oz.)",
                key: "setpointvolume",
                align: "center",
                render: (row, column) => `${row[column.key]}`,
            },
            {
                title: "Number of Sales",
                key: "totalpour",
                align: "center",
                render: (row, column) => (
                    <div style={{ color: "#76ff03" }}>{row[column.key]}</div>
                ),
            },
            {
                title: "Cost ($)",
                key: "cost",
                align: "center",
                render: (row, column) => {
                    return `$ ${row[column.key] ?? 20}`;
                },
            },
            {
                title: "Retail Price ($)",
                key: "setpointprice",
                align: "center",
                render: (row, column) => `$ ${row[column.key]}`,
            },
            {
                title: "Pour Cost ($)",
                key: "",
                align: "center",
                render: (row, column) =>
                    `$ ${(
                        (row["setpointvolume"] * (row["cost"] ?? 20)) /
                        25.36
                    ).toFixed(2)}`,
            },
            {
                title: "Total Pour Volume (oz.)",
                key: "",
                align: "center",
                render: (row, column) =>
                    `${(row["setpointvolume"] * row["totalpour"]).toFixed(2)}`,
            },
            {
                title: "Total Pour Cost ($)",
                key: "",
                align: "center",
                render: (row, column) =>
                    `$ ${(
                        (row["setpointvolume"] *
                            (row["cost"] ?? 20) *
                            row["totalpour"]) /
                        25.36
                    ).toFixed(2)}`,
            },
            {
                title: "Cost Percentage",
                key: "",
                align: "center",
                render: (row, column) => (
                    <div style={{ color: "#EB9739" }}>
                        {`${(
                            (row["cost"] * row["setpointvolume"] * 29.6 * 100) /
                            (row["setpointprice"] * 750)
                        ).toFixed(2)} %`}
                    </div>
                ),
            },
        ],
    };

    const header = (
        <div className={styles.header}>
            <DropDown
                activator={
                    <div className={styles.title} onClick={handleOpen}>
                        {title}
                    </div>
                }
                onRequestClose={handleClose}
                isOpen={isOpen}
                offsetX="11px"
                offsetY="16px"
            >
                <div className={styles.aggregationList}>{locationList}</div>
            </DropDown>

            <div>
                <div>
                    <DateRangeSelector
                        dateRange={selectedDates}
                        onSetDateRange={setSelectedDates}
                        maxDaysPast={45}
                        timezone="America/Los_Angeles"
                    />
                </div>
            </div>
            <div
                style={{
                    justifySelf: "end",
                    alignSelf: "center",
                    boxShadow: "2px 2px 25px rgb(0 0 0 / 60%)"
                }}
            >
                <button style={{
                    padding: "10px 20px",
                    background: "linear-gradient(101.12deg, #3A476F 0%, #2C3656 100%)",
                    borderRadius: "20px"
                }} onClick={handleDownloadClick} disabled={isDownloading}>
                    {isDownloading ? "Downloading..." : "Download Report"}
                </button>{" "}
            </div>
        </div>
    );

    const PanelLayout = () => {
        return (
            <div
                key={"onePanel"}
                className={`${panelStyles.layout} ${panelStyles.onePanel}`}
            >
                <Panel
                    header={header}
                    title={
                        <div
                            style={{
                                textAlign: "center",
                                font: "20px",
                                margin: "10px 0 0 0",
                                fontWeight: "bold",
                            }}
                        >
                            Liquor Composite
                        </div>
                    }
                >
                    {isLoading && <Loader />}
                    {!isLoading && items != null && (
                        <div className={styles.table}>
                            <Table columns={tableData.columns} rows={items} />
                        </div>
                    )}
                </Panel>
            </div>
        );
    };

    return (
        <>
            <div className={styles.page}>
                <Header
                    onClickSettings={handleOpenSettings}
                    env={config.envName}
                />
                <PanelLayout />
            </div>
            <Sidebar
                isOpen={isSettingsOpen}
                onRequestClose={handleCloseSettings}
                onSetLocation={handleSetLocation}
                location={location}
            />
        </>
    );
}
