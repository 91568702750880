import React, {useState, useMemo} from "react";
import styles from "./PpmChart.module.css";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import * as d3 from "d3";

const RADIUS = 155;
export default function PpmChart({data}) {
    const [ringPath, setRingPath] = useState("");
    
    useAnimationFrame(() => {
        const d = new Date();
        const angle = d.getSeconds() * 6 + d.getMilliseconds() * 0.006;
        const rad = Math.PI * angle / 180;
        setRingPath(d3.arc()({startAngle: 0, endAngle: rad, innerRadius: RADIUS, outerRadius: RADIUS}));
    }, []);
    
    const triangles = useMemo(() => {
        const result = [];
        const now = new Date();
        for(let p of data.pours) {
            const d = new Date(p.dateTime);
            if (d.getDate()===now.getDate()&&d.getHours()===now.getHours()&&d.getMinutes()===now.getMinutes()) {
                result.push(d.getSeconds() * 6);
            }
        }
        return result;
    }, [data]);

    return (
        <>
            <svg className={styles.chart} preserveAspectRatio="xMidYMid meet" width="100%" height="100%"
                 viewBox="0 0 456 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    {
                        <filter id="ppm_circle_blur" x="-50" y="-50" width="400" height="400">
                            <feGaussianBlur stdDeviation="15"/>
                        </filter>
                    }
                    <filter id="ppm_circle_blur" x="-1000%" y="-1000%" width="2000%" height="2000%">
                        <feGaussianBlur stdDeviation="15"/>
                    </filter>
                    <linearGradient id="ppm_triangle" x1="12.002" y1="20.1545" x2="12.002" y2="-5.84546"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FB9FEC"/>
                        <stop offset="1" stopColor="#FF49E2"/>
                    </linearGradient>
                </defs>
                <g transform="translate(0, 20)">
                    <g transform="translate(228, 0)" textAnchor="middle">
                        <text className={styles.label} x="0" y="450">PPM Average - Last Hour</text>
                        <g transform="translate(0, 210)">
                            <text className={styles.averageValue} x="0" y="0" textAnchor="middle"
                                  dominantBaseline="central">
                                {data.average}
                            </text>
                            <path d={ringPath} strokeWidth="10" stroke="#35FFF8"
                                    filter="url(#ppm_circle_blur)"
                                    opacity="0.8"/>
                            <path d={ringPath} strokeWidth="3" stroke="#EBFFFE"/>
                            { triangles.map((t,i) => (
                                <g key={i} transform={`rotate(${t})`}>
                                    <g transform="translate(0, -183)">
                                        <g transform="translate(-13, 0)">
                                            <path d="M12.002 20.1545L0.743626 0.654539H23.2603L12.002 20.1545Z"
                                                  fill="url(#ppm_triangle)"/>
                                        </g>
                                    </g>
                                </g>
                            ))}
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
}
