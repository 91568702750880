import React, { useCallback, useContext, useState } from "react";
import { css } from "@emotion/react";
import LocationContext from "../../contexts/LocationContext";
import useStorageState from "../../hooks/useStorageState";
import Header from "../Header";
import Sidebar from "../Sidebar";

interface Config {
    envName: string;
}
interface Props {
    config: Config;
}


const headerStyle = {
    width: "100%",
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    background: "linear-gradient(119.63deg, #1e324f 2.35%, #07284f 93.43%)"
};

const PageLayout: React.FC<Props> = ({ config, children }) => {
    const loc: any = useContext(LocationContext);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [location, setLocation] = useStorageState(
        "location",
        loc?.flat[0].locationId
    );

    const handleOpenSettings = useCallback(() => setIsSettingsOpen(true), []);
    const handleCloseSettings = useCallback(() => setIsSettingsOpen(false), []);
    const handleSetLocation = useCallback((v) => setLocation(v), [setLocation]);

    return (
        <>
            <div style={headerStyle}>
                <Header
                    // onDownloadReport={handleDownloadReport}
                    onClickSettings={handleOpenSettings}
                    env={config.envName}
                />
                {children}
            </div>
            <Sidebar
                isOpen={isSettingsOpen}
                onRequestClose={handleCloseSettings}
                onSetLocation={handleSetLocation}
                location={location}
            />
        </>
    );
};

export default PageLayout;
