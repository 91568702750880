import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import DropDown from "../components/DropDown";
import Header from "../components/Header";
import PageLayout from "../components/layouts/PageLayout";
import Panel from "../components/Panel";
import Sidebar from "../components/Sidebar";
import LocationContext from "../contexts/LocationContext";
import useStorageState from "../hooks/useStorageState";
import styles from "./LiveViewPage.module.css";
import panelStyles from "../components/PanelLayout.module.css";
import Loader from "../components/Loader";
import Table from "../components/Table";
import Gen4GraphqlContext from "../contexts/Gen4GraphqlContext";
import { importCSV } from "../utils/csvImporter";
import {
    Alert,
    Box,
    IconButton,
    LinearProgress,
    LinearProgressProps,
    Snackbar,
    Typography,
} from "@mui/material";
import { Delete, FileUpload } from "@mui/icons-material";

interface Config {
    envName: string;
}
interface Props {
    config: Config;
}

const LiquorPage: React.FC<Props> = ({ config }) => {
    const PAGESIZE = 12;
    const gen4Graphql = useContext(Gen4GraphqlContext);
    const loc: any = useContext(LocationContext);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<any[] | null>(null);
    const [page, setPage] = useState(1);
    const [queryToken, setQueryToken] = useState<string | null>(null);
    const [nextPageToken, setNextPageToken] = useState<string | null>(null);
    const [previousPageTokens, setPreviousPageTokens] = useState<
        (string | null)[]
    >([]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);

    const [locationId, setLocationId] = useStorageState(
        "location",
        loc?.flat[0].locationId
    );

    const handleOpenSettings = useCallback(() => setIsSettingsOpen(true), []);
    const handleCloseSettings = useCallback(() => setIsSettingsOpen(false), []);
    const handleSetLocation = useCallback(
        (v) => setLocationId(v),
        [setLocationId]
    );
    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleClickLocation = useCallback(
        (e) => {
            handleSetLocation(e.target.value);
            setIsOpen(false);
        },
        [handleSetLocation]
    );

    const handleNextPageClick = () => {
        setPage(page + 1);
        setItems([]);
        setIsLoading(true);
        setPreviousPageTokens([...previousPageTokens, queryToken]);
        setQueryToken(nextPageToken);
    };
    const handlePreviousPageClick = () => {
        setPage(page - 1);
        setItems([]);
        setIsLoading(true);
        let token = previousPageTokens.pop();
        setPreviousPageTokens(previousPageTokens);
        setQueryToken(token ?? null);
    };
    console.log(locationId);

    const handleImportCsv = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        let file = e?.target?.files[0];
        let parentId = loc.byId[locationId].parentId;
        let userId = loc.root.user.id;
        setSnackbarOpen(true);
        setProgress(0);


        await importCSV({
            file,
            locationId,
            parentId,
            userId,
            onProgress: onImportProgress,
            onSuccess: onImportSuccess,
        });
    };

    const onImportProgress = (current: number, total: number) => {
        let progress = Math.round((current * 100) / total);
        console.log(progress);
        setProgress(progress);
    };
    const onImportSuccess = async () => {
        setSnackbarOpen(false);
        await fetch();
    };

    const tableData = {
        columns: [
            {
                title: "ID",
                key: "id",
                align: "center",
                render: (row: any, column: any, index: number) =>
                    (page - 1) * PAGESIZE + (index + 1),
            },
            {
                title: "Image",
                dataIndex: "thumbnail",
                key: "thumbnail",
                width: "10%",
                align: "center",
                render: (row: any, column: any, index: number) => {
                    let value = row[column.key];
                    if (value) {
                        return (
                            <img
                                src={value}
                                width={80}
                                style={{ maxHeight: 120, objectFit: "contain" }}
                            />
                        );
                    }
                    return value;
                },
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                align: "center",
            },
            {
                title: "Brand",
                dataIndex: "brand",
                align: "center",
                key: "brand",
            },

            {
                title: "",
                dataIndex: "",
                align: "center",
                key: "x",
                width: "5%",
                render: (row: any, column: any, index: number) => {
                    let id = row.id;
                    return (
                        <IconButton aria-label="delete" color="error">
                            <Delete />
                        </IconButton>
                    );
                },
            },
        ],
    };

    const title = useMemo(() => {
        return `${loc.root.name} - ${loc.byId[locationId]?.name}`;
    }, [locationId, loc]);

    const locationList = useMemo(
        () =>
            loc.root.locations.map(({ level, locationId, name }: any) => (
                <button
                    key={locationId}
                    value={locationId}
                    className={`${level > 0 ? styles.indent : ""} ${
                        locationId === locationId ? styles.active : ""
                    }`}
                    onClick={handleClickLocation}
                >
                    {`${loc.root.name} - ${name}`}
                </button>
            )),
        [loc, locationId, handleClickLocation]
    );

    const fetch = async () => {
        setIsLoading(true);
        setItems([]);
        var items = [];
        var token = null;

        const data = await gen4Graphql?.listLiquor(
            locationId,
            PAGESIZE,
            queryToken
        );
        items = data?.listSpoutGen4LiquorByLocationId?.items ?? [];
        token = data?.listSpoutGen4LiquorByLocationId?.nextToken ?? null;
        setItems(items);
        setNextPageToken(token);
        setIsLoading(false);
    }

    useEffect(() => {
        let cancel = false;

        let prevDataStr = "";

        
        fetch();

        return () => {
            cancel = true;
        };
    }, [locationId, queryToken]);

    const header = (
        <div className={styles.header}>
            <DropDown
                activator={
                    <div className={styles.title} onClick={handleOpen}>
                        {title}
                    </div>
                }
                onRequestClose={handleClose}
                isOpen={isOpen}
                offsetX="11px"
                offsetY="16px"
            >
                <div className={styles.aggregationList}>{locationList}</div>
            </DropDown>
            <div></div>
            <div
                style={{
                    justifySelf: "end",
                    alignSelf: "center",
                    boxShadow: "2px 2px 25px rgb(0 0 0 / 60%)",
                }}
            >
                <div>
                    <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        accept=".csv"
                        onChange={handleImportCsv}
                    />
                    <label htmlFor="contained-button-file">
                        <span
                            style={{
                                padding: "10px 20px",
                                background:
                                    "linear-gradient(101.12deg, #3A476F 0%, #2C3656 100%)",
                                borderRadius: "20px",
                            }}
                            // disabled={isDownloading}
                        >
                            {isDownloading ? "Uploading..." : "Import CSV"}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );

    const LinearProgressWithLabel = (
        props: LinearProgressProps & { value: number }
    ) => {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "300px", mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    };
    return (
        <PageLayout config={config}>
            <div
                key={"onePanel"}
                className={`${panelStyles.layout} ${panelStyles.onePanel}`}
            >
                <Panel header={header} title={""}>
                    {isLoading && <Loader />}
                    {!isLoading && items != null && (
                        <div className={styles.table}>
                            <Table columns={tableData.columns} rows={items} />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "10px",
                                    width: "90%",
                                    alignSelf: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                <div className="action" style={{}}>
                                    <button
                                        className="action"
                                        disabled={
                                            previousPageTokens.length === 0
                                        }
                                        onClick={handlePreviousPageClick}
                                        style={{
                                            padding: "0 13px",
                                            borderRadius: "15px 0 0 15px",
                                        }}
                                    >
                                        {"<"}
                                    </button>
                                    <button
                                        className="action"
                                        disabled={nextPageToken === null}
                                        onClick={handleNextPageClick}
                                        style={{
                                            padding: "0 13px",
                                            borderRadius: "0 15px 15px 0 ",
                                        }}
                                    >
                                        {">"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Panel>
            </div>
            <Snackbar open={snackbarOpen}>
                <Alert icon={<FileUpload fontSize="inherit" />} severity="info">
                    {" "}
                    <LinearProgressWithLabel value={progress} />
                </Alert>
            </Snackbar>
        </PageLayout>
    );
};

export default LiquorPage;
