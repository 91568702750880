import { CreateSpoutGen4LiquorInput } from "./gen4/API";
import Gen4Graphql from "./gen4/gen4Graphql";
import Inventory from "./inventory";
import Papa from "papaparse";

interface IImportCSV {
    userId: string;
    locationId: string;
    parentId: string;
    file: File;
    onError?: (error: Error) => void;
    onProgress?: (current: number, total: number) => void;
    onSuccess?: () => void;
}
const importCSV = async (option: IImportCSV) => {
    const {
        userId,
        locationId,
        parentId,
        file,
        onError,
        onProgress,
        onSuccess,
    } = option;
    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
            let csv = results.data as any[];
            for (let index = 0; index < csv.length; index++) {
                let data = csv[index] as any;
                if (onProgress) {
                    onProgress!(index + 1, csv.length);
                }
                const addProduct = {
                    accountId: userId,
                    locationId: parentId,
                    name: data.name,
                    brand: data.brand,
                    thumbnail: data.thumbnail,
                    category: [data.category],
                    size: Number(data.size),
                    unitOfSize: data.unitOfSize,
                };
                let inventoryService = new Inventory();
                let listProductByName =
                    await inventoryService.listProductByName(
                        parentId,
                        addProduct.name
                    );
                var product: any = {};
                if (listProductByName?.items?.length! > 0) {
                    product = listProductByName?.items![0]!;
                    let inventory = product.inventoies.items.find(
                        (i: any) =>
                            i.productId == product.id &&
                            i.locationId == locationId
                    );
                    if (!inventory) {
                        await inventoryService.addStock(
                            product.id,
                            locationId,
                            Number(data.stock)
                        );
                    } else {
                        let newStock = Number(data.stock) + inventory.stock;
                        await inventoryService.updateInventory(
                            inventory.id,
                            newStock
                        );
                    }
                } else {
                    product = await inventoryService.addProduct(addProduct);
                    await inventoryService.addStock(
                        product.id,
                        locationId,
                        Number(data.stock)
                    );
                }
                const gen4Service = new Gen4Graphql();
                const liqours = await gen4Service.listLiqourByName(
                    locationId,
                    data.name
                );
                if (liqours?.items?.length! > 0) {
                    continue;
                }
                const addLiquor: CreateSpoutGen4LiquorInput = {
                    accountId: userId,
                    locationId: locationId,
                    productId: product.id,
                    name: data.name,
                    brand: data.brand,
                    thumbnail: data.thumbnail,
                    setpoints: [
                        Number(data.pourSetpoint1),
                        Number(data.pourSetpoint2),
                        Number(data.pourSetpoint3),
                        Number(data.pourSetpoint4),
                    ],
                    setpointsOz: [
                        Number(data.pourSetpoint1Oz),
                        Number(data.pourSetpoint2Oz),
                        Number(data.pourSetpoint3Oz),
                        Number(data.pourSetpoint4Oz),
                    ],
                    prices: [
                        Number(data.price1),
                        Number(data.price2),
                        Number(data.price3),
                        Number(data.price4),
                    ],
                    remaining: data.volRemaining,
                    currency: data.currency,
                    locationName: data.locationName,
                    pourtime: data.pourTime,
                    size: data.size,
                    bottleSensor: data.bottleSensor,
                    cost: data.cost,
                    IQSReg05: data.IQSReg05,
                    IQSReg06: data.IQSReg06,
                    IQSReg07: data.IQSReg07,
                    IQSReg08: data.IQSReg08,
                    IQSReg09: data.IQSReg09,
                    IQSReg0A: data.IQSReg0A,
                    IQSReg0B: data.IQSReg0B,
                    expDelta: data.expDelta,
                    liquidWait: data.liquidWait,
                };
                const createdGen4Liqour = await gen4Service.addLiquor(
                    addLiquor
                );
                await sleep(100);
            }
            if (onProgress) {
                onProgress!(csv.length, csv.length);
            }
            if (onSuccess) {
                onSuccess();
            }
        },
    });

    function sleep(ms = 0) {
        return new Promise((r) => setTimeout(r, ms));
    }
};

export { importCSV };
