import React, { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import LoginPage from "../pages/LoginPage";

const AuthContext = React.createContext(null);

export function AuthWrapper({ config, children }) {
    const [state, setState] = useState("loading");
    const [user, setUser] = useState(null);
    
    function handleAuthEvent({payload: {event, data}}) {
        switch(event) {
            case "signIn":
                setUser(data);
                setState("signedIn");
                break;
            case "signOut":
                setState("signedOut");
                setUser(null);
                break;
            default:
                break;
        }
    }
    
    useEffect(() => {
        Hub.listen("auth", handleAuthEvent);
        Auth.configure({
            ...config,
            identityPoolId: 'us-east-2:3a15ef36-55bd-43fc-920f-50a190ecab77',
            // mandatorySignIn: true,
            // authenticationFlowType: "USER_SRP_AUTH"
        });
        (async function() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user);
                setState("signedIn");
            }
            catch {
                setState("signedOut");
            }
        })();
        return () => Hub.remove("auth", handleAuthEvent);
    }, [config]);
    
    function renderSwitch() {
        switch(state) {
            case "signedIn":
                return (
                    <AuthContext.Provider value={user}>
                        {children}
                    </AuthContext.Provider>
                );
            case "signedOut":
                return (
                    <LoginPage />
                );
            default:
                return <></>;
        }
    }
    
    return renderSwitch();
}

export default AuthContext;
