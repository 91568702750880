import React, {useState, useCallback, useMemo, cloneElement} from "react";
import styles from "./PanelLayout.module.css";
import ChartPanel from "./ChartPanel";
import useImmediate from "../hooks/useImmediate";
import aggregationsMap from "../defs/aggregationsMap";

export default function PanelLayout({mode, location}) {
    const [max, setMax] = useState(null);
    const handleSetMax = useCallback(key => setMax(key), []);

    useImmediate(() => setMax(null), [mode]);

    const panels = useMemo(() => {
        const aggregations = aggregationsMap[mode];
        switch (mode) {
            case "overview":
                return [
                    <ChartPanel key="a" aggregations={aggregations.main} aggregation="salesByItem" canMaximize/>,
                    <ChartPanel key="b" aggregations={aggregations.aux} aggregation="salesBreakdown"/>,
                    <ChartPanel key="c" aggregations={aggregations.main} aggregation="barSales" canMaximize/>
                ];
            case "pours":
                return [
                    <ChartPanel key="a" aggregations={aggregations.main} aggregation="minuteTracking" canMaximize/>,
                    <ChartPanel key="b" aggregations={aggregations.aux} aggregation="poursPerMinute"/>,
                    <ChartPanel key="c" aggregations={aggregations.main} aggregation="ppmHistory" canMaximize/>
                ];
            default:
                return [];
        }
    }, [mode]);
    
    return (
        <div key={!!max ? "onePanel" : "threePanel"} 
            className={`${styles.layout} ${!!max ? styles.onePanel : styles.threePanel}`}>
            {panels.filter(p => !max || p.key === max)
                .map(p => cloneElement(p, {
                    panelKey: p.key,
                    location,
                    onSetMax: handleSetMax,
                    isMaximized: max === p.key
                }))}
        </div>
    );
}