import React, {useState, useRef, useCallback, useMemo} from "react";
import Tooltip from "../Tooltip";
import useD3 from "../../hooks/useD3";
import * as d3 from "d3";
import styles from "./BottleChart.module.css";

// neck: height = 0.15, width = 0.3, area = 0.045
// cone: height = 0.23, area = 0.069 + 0.0805 = 0.1495
// body: height = 0.62, width = 1, area = 0.62
// total area: 0.8145
// taper function: y=(0.7/0.23)x+0.3 
// taper integral: 1.52174x^2 + 0.3x
// solved for x: (100(sqrt(5)*sqrt(76087y+1125)-75)) / 76087
function calcBottleFill(pct) {
    const neckArea = 0.045;
    const neckHeight = 0.15;
    const taperArea = 0.1495;
    const bodyArea = 0.62;
    const bodyHeight = 0.62;
    const area = 0.8145;

    let v = pct * area;
    let result = Math.min(1, v / neckArea) * neckHeight;
    v = Math.max(0, v - neckArea);
    const y = Math.min(v, taperArea);
    const x = 100 * (Math.sqrt(5) * Math.sqrt(76087 * y + 1125) - 75) / 76087;
    result += x;
    v = Math.max(0, v - taperArea);
    result += Math.min(1, v / bodyArea) * bodyHeight;
    return result;
}

export default function BottleChart({data, detail}) {
    const [tooltip, setTooltip] = useState(null);
    const tooltipRef = useRef();

    const {percentageMispours, successVolume, mispourVolume, sales, mispours} = data;
    const fillPct = calcBottleFill(percentageMispours);

    const handleMouseMove = useCallback(e => {
        const t = tooltipRef.current;
        t.style.left = `${e.clientX}px`;
        t.style.bottom = `${window.innerHeight - e.clientY + 10}px`;
    }, []);
    const handleMouseLeave = useCallback(e => {
        setTooltip(null);
    }, []);
    const handleMouseEnter = useCallback(e => {
        if (detail) {
            setTooltip(detail(data));
            handleMouseMove(e);
        }
    }, [data, detail, handleMouseMove]);

    const fillRef = useRef();
    useD3(() => {
        d3.select(fillRef.current)
            .transition()
            .duration(1000)
            .attr("y", 73 + 328 * fillPct)
            .attr("height", 328 * (1 - fillPct));
    }, [data]);

    const percentageSuccessPours = useMemo(() => {
        let totalpour = sales + mispours;
        if (totalpour > 0){
            return Math.round((sales * 100)/totalpour);
        }
        return 0;
    }, [sales, mispours])

    return (
        <>
            <svg className={styles.chart} preserveAspectRatio="xMidYMid meet" width="100%" height="100%"
                 viewBox="0 0 495 431" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* percent text */}
                <text x="225" y="231" className={styles.valueText}>{percentageSuccessPours} %</text>
                <text x="225" y="291" className={styles.titleText}>Complete</text>
                {/* grey line */}
                {/* <path d="M184.793 161L316.793 161" stroke="#E7E7E7"/> */}
                <g onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                    {/* red fill */}
                    <g clipPath="url(#bottle_clip)">
                        <rect x="47" y="73" width="103" height="328" fill="url(#bottle_red_fill)"/>
                    </g>
                    {/* blue fill */}
                    <g clipPath="url(#bottle_clip)" filter="url(#bottle_shadow)">
                        <rect ref={fillRef} x="47" y="73" width="103" height="328" fill="url(#bottle_blue_fill)"/>
                    </g>
                    {/* dark blue outline */}
                    <path
                        d="M80.4955 103.152C80.4955 113.723 78.1737 124.034 73.9945 133.132L50.6436 183.992C47.7248 190.317 46 197.682 46 205.047V376.604C46 390.121 54.7566 401.385 65.1052 401.385H130.381C140.73 401.385 149.487 390.121 149.487 376.604V205.047C149.487 197.595 147.762 190.317 144.843 183.992L121.359 133.132C117.247 124.121 114.991 113.723 114.991 103.152V73H80.4955V103.152Z"
                        stroke="#1B3B6B" strokeWidth="5"/>
                    {/* cap */}
                    <g transform="translate(80,0)">
                        <path
                            d="M34.5584 27.4601C34.2448 22.7134 32.9905 20.2984 31.2762 17.6544C29.5619 15.0104 24.7326 8.18182 24.2936 7.70298C23.8755 7.70298 23.6037 7.70298 23.6037 7.70298L21.6177 0.104095H18.0009H14.3842L12.3982 7.70298C12.3982 7.70298 12.1055 7.70298 11.7083 7.70298C11.2483 8.18182 6.43996 15.0104 4.72568 17.6544C3.01139 20.2984 1.75704 22.7134 1.44345 27.4601C1.12986 32.2068 0.502681 43.8029 0.105469 59.8959H18.0009H35.8964C35.4992 43.7821 34.872 32.2068 34.5584 27.4601Z"
                            fill="#1B3B6B" stroke="#1B3B6B" strokeMiterlimit="10"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="bottle_clip">
                        <path
                            d="M80.4955 103.152C80.4955 113.723 78.1737 124.034 73.9945 133.132L50.6436 183.992C47.7248 190.317 46 197.682 46 205.047V376.604C46 390.121 54.7566 401.385 65.1052 401.385H130.381C140.73 401.385 149.487 390.121 149.487 376.604V205.047C149.487 197.595 147.762 190.317 144.843 183.992L121.359 133.132C117.247 124.121 114.991 113.723 114.991 103.152V73H80.4955V103.152Z"
                        />
                    </clipPath>
                    <filter id="bottle_shadow" width="140%" height="140%" filterUnits="userSpaceOnUse">
                        <feDropShadow dx="0" dy="0" stdDeviation="7" floodOpacity="0.8"/>
                    </filter>
                    <linearGradient id="bottle_blue_fill" x1="1" y1="1" x2="0" y2="0.4">
                        <stop offset="0%" stopColor="#55C4FF" stopOpacity={1}/>
                        <stop offset="80%" stopColor="#29F9ED" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="bottle_red_fill" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor="#EA6057" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#FC302F" stopOpacity={1}/>
                    </linearGradient>
                </defs>
            </svg>
            <Tooltip content={tooltip} ref={tooltipRef}/>
        </>
    );
}
