import React, {useState, useRef} from "react";
import Tooltip from "../Tooltip";
import useD3 from "../../hooks/useD3";
import useImmediate from "../../hooks/useImmediate";
import * as d3 from "d3";

let uniqueId = 0;

const MARGIN = {top: 20, right: 58, bottom: 44, left: 58};

export default function PieChart({data, width, height, detail}) {
    const [clipId] = useState(() => "pie_clip_" + uniqueId++);
    const [shadowId] = useState(() => "pie_shadow_" + uniqueId++);

    const [tooltip, setTooltip] = useState(null);
    const tooltipRef = useRef();

    function handleMouseMove(e) {
        const t = tooltipRef.current;
        t.style.left = `${e.clientX}px`;
        t.style.bottom = `${window.innerHeight - e.clientY + 10}px`;
    }
    function handleMouseLeave(e) {
        setTooltip(null);
    }

    const animate = useImmediate(() => true, [data]);

    const ref = useD3((g) => {
        function handleMouseEnter(e) {
            if (detail) {
                const d = d3.select(this).datum();
                setTooltip(detail(d));
                handleMouseMove(e);
            }
        }
        
        const sorted = data.filter(d => d.value > 0).sort((d1,d2) => d2.value-d1.value);

        const cScale = d3.scaleOrdinal()
            .domain(sorted.map(d => d.label))
            .range(sorted.length % 6 === 0 ? [1, 2, 3, 4] : [1, 2, 3, 4, 5]);
        sorted.reverse();
        
        const pie = d3.pie()
            .value(d => d.value)
            .startAngle(-Math.PI * 2)
            .endAngle(0)
            .sort(null)
            (sorted);
        
        const cx = MARGIN.left + (width - MARGIN.left - MARGIN.right) / 2;
        const cy = MARGIN.top + (height - MARGIN.top - MARGIN.bottom) / 2;
        const r = Math.min(cx - MARGIN.left, cy - MARGIN.top);

        g.append("defs")
            .append("clipPath")
            .attr("id", clipId)
            .append("circle")
            .attr("cx", "0")
            .attr("cy", "0")
            .attr("r", r);
        g.append("defs")
            .append("filter")
            .attr("id", shadowId)
            .attr("width", "140%")
            .attr("height", "140%")
            .append("feDropShadow")
            .attr("dx", "0")
            .attr("dy", "0")
            .attr("stdDeviation", r * 0.0746);

        let sel = g.append("g").attr("class", "plot")
            .attr("transform", `translate(${cx}, ${cy}) rotate(-40)`)
            .selectAll("g")
            .data(pie)
            .enter()
            .append("path")
            .attr("fill", d => `url(#pie_fill_${cScale(d.data.label)})`)
            .attr("filter", `url(#${shadowId})`)
            .attr("clip-path", `url(#${clipId})`)
            .attr("d", d3.arc().innerRadius(0).outerRadius(r))
            .attr("transform", "scale(0)")
            .on("mouseenter", handleMouseEnter)
            .on("mousemove", handleMouseMove)
            .on("mouseleave", handleMouseLeave);
        
        if (animate) sel = sel.transition().duration(500).delay((d, i) => (sorted.length - i) * (600 / sorted.length));
        sel.attr("transform", "scale(1)");
    }, [data, width, height]);

    return (
        <>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <defs>
                    <filter id="pie_shadow" width="140%" height="140%" filterUnits="userSpaceOnUse">
                        <feDropShadow dx="0" dy="0" stdDeviation="10"/>
                    </filter>
                    <linearGradient id="pie_fill_1" x1="1" y1="1" x2="0" y2="0">
                        <stop offset="19%" stopColor="#55C4FF" stopOpacity={1}/>
                        <stop offset="83%" stopColor="#29F9ED" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="pie_fill_2" x1="1" y1="0.5" x2="0" y2="0.5">
                        <stop offset="0%" stopColor="#FB9FEC" stopOpacity={1}/>
                        <stop offset="72%" stopColor="#DA0AB9" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="pie_fill_3" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="49%" stopColor="#39FF4D" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#90FF5C" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="pie_fill_4" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="54%" stopColor="#FF2727" stopOpacity={1}/>
                        <stop offset="100%" stopColor="#E96259" stopOpacity={1}/>
                    </linearGradient>
                    <linearGradient id="pie_fill_5" x1="0" y1="0" x2="0.4" y2="1">
                        <stop offset="16%" stopColor="#EEFF89" stopOpacity={1}/>
                        <stop offset="83%" stopColor="#F99929" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                <g ref={ref}>
                </g>
            </svg>
            <Tooltip content={tooltip} ref={tooltipRef}/>
        </>
    );
}
