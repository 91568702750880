/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSpoutGen4EventDataInput = {
  id?: string | null,
  userId: string,
  locationId: string,
  datetime: string,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  eventData?: string | null,
  liquorId: string,
  liquorName?: string | null,
  ttl?: number | null,
  datetimeEventType?: string | null,
};

export type ModelSpoutGen4EventDataConditionInput = {
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  ipadDatetime?: ModelStringInput | null,
  eventId?: ModelIntInput | null,
  spoutId?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  rsoc?: ModelIntInput | null,
  eventType?: ModelIntInput | null,
  eventData?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  liquorName?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  datetimeEventType?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4EventDataConditionInput | null > | null,
  or?: Array< ModelSpoutGen4EventDataConditionInput | null > | null,
  not?: ModelSpoutGen4EventDataConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type SpoutGen4EventData = {
  __typename: "SpoutGen4EventData",
  id?: string,
  userId?: string,
  locationId?: string,
  datetime?: string,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  eventData?: string | null,
  liquorId?: string,
  liquorName?: string | null,
  ttl?: number | null,
  datetimeEventType?: string | null,
  liquor?: SpoutGen4Liquor,
  createdAt?: string,
  updatedAt?: string,
};

export type SpoutGen4Liquor = {
  __typename: "SpoutGen4Liquor",
  id?: string,
  accountId?: string,
  locationId?: string,
  productId?: string,
  name?: string | null,
  brand?: string | null,
  thumbnail?: string | null,
  setpoints?: Array< number | null > | null,
  prices?: Array< number | null > | null,
  remaining?: number | null,
  currency?: string | null,
  locationName?: string | null,
  pourtime?: string | null,
  size?: number | null,
  setpointsOz?: Array< number | null > | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  IQSReg05?: number | null,
  IQSReg06?: number | null,
  IQSReg07?: number | null,
  IQSReg08?: number | null,
  IQSReg09?: number | null,
  IQSReg0A?: number | null,
  IQSReg0B?: number | null,
  expDelta?: number | null,
  liquidWait?: number | null,
  createAt?: string | null,
  updateAt?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateSpoutGen4EventDataInput = {
  id: string,
  userId?: string | null,
  locationId?: string | null,
  datetime?: string | null,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  eventData?: string | null,
  liquorId?: string | null,
  liquorName?: string | null,
  ttl?: number | null,
  datetimeEventType?: string | null,
};

export type DeleteSpoutGen4EventDataInput = {
  id: string,
};

export type CreateSpoutGen4EventPourDataInput = {
  id?: string | null,
  userId: string,
  locationId: string,
  datetime: string,
  liquorId: string,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  liquorType?: number | null,
  pourVolume?: number | null,
  pourStatus?: number | null,
  volumeRemaining?: number | null,
  totalPourSuccess?: number | null,
  totalPourFailed?: number | null,
  totalPourAmount?: number | null,
  liquorName?: string | null,
  pourSelection?: number | null,
  unitPrice?: number | null,
  volumePrice?: number | null,
  ttl?: number | null,
  setpointVolume?: number | null,
  setpointPrice?: number | null,
  bottleSwitchStatus?: number | null,
  pourTime?: number | null,
  setpointTime?: number | null,
  errorCode?: number | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  ltaOnCharger?: number | null,
  ltaOnBottle?: number | null,
  proxDuringLiquidDetect1?: number | null,
  proxDuringLiquidDetect2?: number | null,
};

export type ModelSpoutGen4EventPourDataConditionInput = {
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  ipadDatetime?: ModelStringInput | null,
  eventId?: ModelIntInput | null,
  spoutId?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  rsoc?: ModelIntInput | null,
  eventType?: ModelIntInput | null,
  liquorType?: ModelIntInput | null,
  pourVolume?: ModelFloatInput | null,
  pourStatus?: ModelIntInput | null,
  volumeRemaining?: ModelFloatInput | null,
  totalPourSuccess?: ModelIntInput | null,
  totalPourFailed?: ModelIntInput | null,
  totalPourAmount?: ModelFloatInput | null,
  liquorName?: ModelStringInput | null,
  pourSelection?: ModelIntInput | null,
  unitPrice?: ModelFloatInput | null,
  volumePrice?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  setpointVolume?: ModelFloatInput | null,
  setpointPrice?: ModelFloatInput | null,
  bottleSwitchStatus?: ModelIntInput | null,
  pourTime?: ModelFloatInput | null,
  setpointTime?: ModelFloatInput | null,
  errorCode?: ModelIntInput | null,
  cost?: ModelFloatInput | null,
  category?: ModelStringInput | null,
  virtualBar?: ModelIntInput | null,
  ltaOnCharger?: ModelIntInput | null,
  ltaOnBottle?: ModelIntInput | null,
  proxDuringLiquidDetect1?: ModelIntInput | null,
  proxDuringLiquidDetect2?: ModelIntInput | null,
  and?: Array< ModelSpoutGen4EventPourDataConditionInput | null > | null,
  or?: Array< ModelSpoutGen4EventPourDataConditionInput | null > | null,
  not?: ModelSpoutGen4EventPourDataConditionInput | null,
};

export type SpoutGen4EventPourData = {
  __typename: "SpoutGen4EventPourData",
  id?: string,
  userId?: string,
  locationId?: string,
  datetime?: string,
  liquorId?: string,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  liquorType?: number | null,
  pourVolume?: number | null,
  pourStatus?: number | null,
  volumeRemaining?: number | null,
  totalPourSuccess?: number | null,
  totalPourFailed?: number | null,
  totalPourAmount?: number | null,
  liquorName?: string | null,
  pourSelection?: number | null,
  unitPrice?: number | null,
  volumePrice?: number | null,
  ttl?: number | null,
  setpointVolume?: number | null,
  setpointPrice?: number | null,
  bottleSwitchStatus?: number | null,
  pourTime?: number | null,
  setpointTime?: number | null,
  errorCode?: number | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  ltaOnCharger?: number | null,
  ltaOnBottle?: number | null,
  proxDuringLiquidDetect1?: number | null,
  proxDuringLiquidDetect2?: number | null,
  liquor?: SpoutGen4Liquor,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateSpoutGen4EventPourDataInput = {
  id: string,
  userId?: string | null,
  locationId?: string | null,
  datetime?: string | null,
  liquorId?: string | null,
  ipadDatetime?: string | null,
  eventId?: number | null,
  spoutId?: string | null,
  engraving?: string | null,
  rsoc?: number | null,
  eventType?: number | null,
  liquorType?: number | null,
  pourVolume?: number | null,
  pourStatus?: number | null,
  volumeRemaining?: number | null,
  totalPourSuccess?: number | null,
  totalPourFailed?: number | null,
  totalPourAmount?: number | null,
  liquorName?: string | null,
  pourSelection?: number | null,
  unitPrice?: number | null,
  volumePrice?: number | null,
  ttl?: number | null,
  setpointVolume?: number | null,
  setpointPrice?: number | null,
  bottleSwitchStatus?: number | null,
  pourTime?: number | null,
  setpointTime?: number | null,
  errorCode?: number | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  ltaOnCharger?: number | null,
  ltaOnBottle?: number | null,
  proxDuringLiquidDetect1?: number | null,
  proxDuringLiquidDetect2?: number | null,
};

export type DeleteSpoutGen4EventPourDataInput = {
  id: string,
};

export type CreateSpoutGen4LiquorInput = {
  id?: string | null,
  accountId: string,
  locationId: string,
  productId: string,
  name?: string | null,
  brand?: string | null,
  thumbnail?: string | null,
  setpoints?: Array< number | null > | null,
  prices?: Array< number | null > | null,
  remaining?: number | null,
  currency?: string | null,
  locationName?: string | null,
  pourtime?: string | null,
  size?: number | null,
  setpointsOz?: Array< number | null > | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  IQSReg05?: number | null,
  IQSReg06?: number | null,
  IQSReg07?: number | null,
  IQSReg08?: number | null,
  IQSReg09?: number | null,
  IQSReg0A?: number | null,
  IQSReg0B?: number | null,
  expDelta?: number | null,
  liquidWait?: number | null,
  createAt?: string | null,
  updateAt?: string | null,
};

export type ModelSpoutGen4LiquorConditionInput = {
  accountId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  setpoints?: ModelFloatInput | null,
  prices?: ModelFloatInput | null,
  remaining?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  pourtime?: ModelStringInput | null,
  size?: ModelIntInput | null,
  setpointsOz?: ModelFloatInput | null,
  cost?: ModelFloatInput | null,
  category?: ModelStringInput | null,
  virtualBar?: ModelIntInput | null,
  bottleSensor?: ModelIntInput | null,
  IQSReg05?: ModelIntInput | null,
  IQSReg06?: ModelIntInput | null,
  IQSReg07?: ModelIntInput | null,
  IQSReg08?: ModelIntInput | null,
  IQSReg09?: ModelIntInput | null,
  IQSReg0A?: ModelIntInput | null,
  IQSReg0B?: ModelIntInput | null,
  expDelta?: ModelIntInput | null,
  liquidWait?: ModelIntInput | null,
  createAt?: ModelStringInput | null,
  updateAt?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4LiquorConditionInput | null > | null,
  or?: Array< ModelSpoutGen4LiquorConditionInput | null > | null,
  not?: ModelSpoutGen4LiquorConditionInput | null,
};

export type UpdateSpoutGen4LiquorInput = {
  id: string,
  accountId?: string | null,
  locationId?: string | null,
  productId?: string | null,
  name?: string | null,
  brand?: string | null,
  thumbnail?: string | null,
  setpoints?: Array< number | null > | null,
  prices?: Array< number | null > | null,
  remaining?: number | null,
  currency?: string | null,
  locationName?: string | null,
  pourtime?: string | null,
  size?: number | null,
  setpointsOz?: Array< number | null > | null,
  cost?: number | null,
  category?: string | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  IQSReg05?: number | null,
  IQSReg06?: number | null,
  IQSReg07?: number | null,
  IQSReg08?: number | null,
  IQSReg09?: number | null,
  IQSReg0A?: number | null,
  IQSReg0B?: number | null,
  expDelta?: number | null,
  liquidWait?: number | null,
  createAt?: string | null,
  updateAt?: string | null,
};

export type DeleteSpoutGen4LiquorInput = {
  id: string,
};

export type CreateSpoutGen4DeviceInput = {
  id?: string | null,
  userId: string,
  locationId: string,
  liquorId: string,
  createAt?: string | null,
  updateAt?: string | null,
  engraving?: string | null,
  spoutId?: string | null,
  volume?: number | null,
  onBottle?: boolean | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelSpoutGen4DeviceConditionInput = {
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  createAt?: ModelStringInput | null,
  updateAt?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  spoutId?: ModelStringInput | null,
  volume?: ModelFloatInput | null,
  onBottle?: ModelBooleanInput | null,
  virtualBar?: ModelIntInput | null,
  bottleSensor?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4DeviceConditionInput | null > | null,
  or?: Array< ModelSpoutGen4DeviceConditionInput | null > | null,
  not?: ModelSpoutGen4DeviceConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type SpoutGen4Device = {
  __typename: "SpoutGen4Device",
  id?: string,
  userId?: string,
  locationId?: string,
  liquorId?: string,
  createAt?: string | null,
  updateAt?: string | null,
  engraving?: string | null,
  spoutId?: string | null,
  volume?: number | null,
  onBottle?: boolean | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  liquor?: SpoutGen4Liquor,
};

export type UpdateSpoutGen4DeviceInput = {
  id: string,
  userId?: string | null,
  locationId?: string | null,
  liquorId?: string | null,
  createAt?: string | null,
  updateAt?: string | null,
  engraving?: string | null,
  spoutId?: string | null,
  volume?: number | null,
  onBottle?: boolean | null,
  virtualBar?: number | null,
  bottleSensor?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSpoutGen4DeviceInput = {
  id: string,
};

export type ModelSpoutGen4EventDataFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  ipadDatetime?: ModelStringInput | null,
  eventId?: ModelIntInput | null,
  spoutId?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  rsoc?: ModelIntInput | null,
  eventType?: ModelIntInput | null,
  eventData?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  liquorName?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  datetimeEventType?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4EventDataFilterInput | null > | null,
  or?: Array< ModelSpoutGen4EventDataFilterInput | null > | null,
  not?: ModelSpoutGen4EventDataFilterInput | null,
};

export type ModelSpoutGen4EventDataConnection = {
  __typename: "ModelSpoutGen4EventDataConnection",
  items?:  Array<SpoutGen4EventData | null > | null,
  nextToken?: string | null,
};

export type ModelSpoutGen4EventPourDataFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  datetime?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  ipadDatetime?: ModelStringInput | null,
  eventId?: ModelIntInput | null,
  spoutId?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  rsoc?: ModelIntInput | null,
  eventType?: ModelIntInput | null,
  liquorType?: ModelIntInput | null,
  pourVolume?: ModelFloatInput | null,
  pourStatus?: ModelIntInput | null,
  volumeRemaining?: ModelFloatInput | null,
  totalPourSuccess?: ModelIntInput | null,
  totalPourFailed?: ModelIntInput | null,
  totalPourAmount?: ModelFloatInput | null,
  liquorName?: ModelStringInput | null,
  pourSelection?: ModelIntInput | null,
  unitPrice?: ModelFloatInput | null,
  volumePrice?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  setpointVolume?: ModelFloatInput | null,
  setpointPrice?: ModelFloatInput | null,
  bottleSwitchStatus?: ModelIntInput | null,
  pourTime?: ModelFloatInput | null,
  setpointTime?: ModelFloatInput | null,
  errorCode?: ModelIntInput | null,
  cost?: ModelFloatInput | null,
  category?: ModelStringInput | null,
  virtualBar?: ModelIntInput | null,
  ltaOnCharger?: ModelIntInput | null,
  ltaOnBottle?: ModelIntInput | null,
  proxDuringLiquidDetect1?: ModelIntInput | null,
  proxDuringLiquidDetect2?: ModelIntInput | null,
  and?: Array< ModelSpoutGen4EventPourDataFilterInput | null > | null,
  or?: Array< ModelSpoutGen4EventPourDataFilterInput | null > | null,
  not?: ModelSpoutGen4EventPourDataFilterInput | null,
};

export type ModelSpoutGen4EventPourDataConnection = {
  __typename: "ModelSpoutGen4EventPourDataConnection",
  items?:  Array<SpoutGen4EventPourData | null > | null,
  nextToken?: string | null,
};

export type ModelSpoutGen4LiquorFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  setpoints?: ModelFloatInput | null,
  prices?: ModelFloatInput | null,
  remaining?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  pourtime?: ModelStringInput | null,
  size?: ModelIntInput | null,
  setpointsOz?: ModelFloatInput | null,
  cost?: ModelFloatInput | null,
  category?: ModelStringInput | null,
  virtualBar?: ModelIntInput | null,
  bottleSensor?: ModelIntInput | null,
  IQSReg05?: ModelIntInput | null,
  IQSReg06?: ModelIntInput | null,
  IQSReg07?: ModelIntInput | null,
  IQSReg08?: ModelIntInput | null,
  IQSReg09?: ModelIntInput | null,
  IQSReg0A?: ModelIntInput | null,
  IQSReg0B?: ModelIntInput | null,
  expDelta?: ModelIntInput | null,
  liquidWait?: ModelIntInput | null,
  createAt?: ModelStringInput | null,
  updateAt?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4LiquorFilterInput | null > | null,
  or?: Array< ModelSpoutGen4LiquorFilterInput | null > | null,
  not?: ModelSpoutGen4LiquorFilterInput | null,
};

export type ModelSpoutGen4LiquorConnection = {
  __typename: "ModelSpoutGen4LiquorConnection",
  items?:  Array<SpoutGen4Liquor | null > | null,
  nextToken?: string | null,
};

export type ModelSpoutGen4DeviceFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  locationId?: ModelStringInput | null,
  liquorId?: ModelIDInput | null,
  createAt?: ModelStringInput | null,
  updateAt?: ModelStringInput | null,
  engraving?: ModelStringInput | null,
  spoutId?: ModelStringInput | null,
  volume?: ModelFloatInput | null,
  onBottle?: ModelBooleanInput | null,
  virtualBar?: ModelIntInput | null,
  bottleSensor?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSpoutGen4DeviceFilterInput | null > | null,
  or?: Array< ModelSpoutGen4DeviceFilterInput | null > | null,
  not?: ModelSpoutGen4DeviceFilterInput | null,
};

export type ModelSpoutGen4DeviceConnection = {
  __typename: "ModelSpoutGen4DeviceConnection",
  items?:  Array<SpoutGen4Device | null > | null,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelFloatKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type CreateSpoutGen4EventDataMutationVariables = {
  input?: CreateSpoutGen4EventDataInput,
  condition?: ModelSpoutGen4EventDataConditionInput | null,
};

export type CreateSpoutGen4EventDataMutation = {
  createSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpoutGen4EventDataMutationVariables = {
  input?: UpdateSpoutGen4EventDataInput,
  condition?: ModelSpoutGen4EventDataConditionInput | null,
};

export type UpdateSpoutGen4EventDataMutation = {
  updateSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpoutGen4EventDataMutationVariables = {
  input?: DeleteSpoutGen4EventDataInput,
  condition?: ModelSpoutGen4EventDataConditionInput | null,
};

export type DeleteSpoutGen4EventDataMutation = {
  deleteSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSpoutGen4EventPourDataMutationVariables = {
  input?: CreateSpoutGen4EventPourDataInput,
  condition?: ModelSpoutGen4EventPourDataConditionInput | null,
};

export type CreateSpoutGen4EventPourDataMutation = {
  createSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpoutGen4EventPourDataMutationVariables = {
  input?: UpdateSpoutGen4EventPourDataInput,
  condition?: ModelSpoutGen4EventPourDataConditionInput | null,
};

export type UpdateSpoutGen4EventPourDataMutation = {
  updateSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpoutGen4EventPourDataMutationVariables = {
  input?: DeleteSpoutGen4EventPourDataInput,
  condition?: ModelSpoutGen4EventPourDataConditionInput | null,
};

export type DeleteSpoutGen4EventPourDataMutation = {
  deleteSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSpoutGen4LiquorMutationVariables = {
  input?: CreateSpoutGen4LiquorInput,
  condition?: ModelSpoutGen4LiquorConditionInput | null,
};

export type CreateSpoutGen4LiquorMutation = {
  createSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpoutGen4LiquorMutationVariables = {
  input?: UpdateSpoutGen4LiquorInput,
  condition?: ModelSpoutGen4LiquorConditionInput | null,
};

export type UpdateSpoutGen4LiquorMutation = {
  updateSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpoutGen4LiquorMutationVariables = {
  input?: DeleteSpoutGen4LiquorInput,
  condition?: ModelSpoutGen4LiquorConditionInput | null,
};

export type DeleteSpoutGen4LiquorMutation = {
  deleteSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSpoutGen4DeviceMutationVariables = {
  input?: CreateSpoutGen4DeviceInput,
  condition?: ModelSpoutGen4DeviceConditionInput | null,
};

export type CreateSpoutGen4DeviceMutation = {
  createSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateSpoutGen4DeviceMutationVariables = {
  input?: UpdateSpoutGen4DeviceInput,
  condition?: ModelSpoutGen4DeviceConditionInput | null,
};

export type UpdateSpoutGen4DeviceMutation = {
  updateSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteSpoutGen4DeviceMutationVariables = {
  input?: DeleteSpoutGen4DeviceInput,
  condition?: ModelSpoutGen4DeviceConditionInput | null,
};

export type DeleteSpoutGen4DeviceMutation = {
  deleteSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type GetSpoutGen4EventDataQueryVariables = {
  id?: string,
};

export type GetSpoutGen4EventDataQuery = {
  getSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSpoutGen4EventDatasQueryVariables = {
  filter?: ModelSpoutGen4EventDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventDatasQuery = {
  listSpoutGen4EventDatas?:  {
    __typename: "ModelSpoutGen4EventDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      eventData?: string | null,
      liquorId: string,
      liquorName?: string | null,
      ttl?: number | null,
      datetimeEventType?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSpoutGen4EventPourDataQueryVariables = {
  id?: string,
};

export type GetSpoutGen4EventPourDataQuery = {
  getSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSpoutGen4EventPourDatasQueryVariables = {
  filter?: ModelSpoutGen4EventPourDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventPourDatasQuery = {
  listSpoutGen4EventPourDatas?:  {
    __typename: "ModelSpoutGen4EventPourDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventPourData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      liquorId: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      liquorType?: number | null,
      pourVolume?: number | null,
      pourStatus?: number | null,
      volumeRemaining?: number | null,
      totalPourSuccess?: number | null,
      totalPourFailed?: number | null,
      totalPourAmount?: number | null,
      liquorName?: string | null,
      pourSelection?: number | null,
      unitPrice?: number | null,
      volumePrice?: number | null,
      ttl?: number | null,
      setpointVolume?: number | null,
      setpointPrice?: number | null,
      bottleSwitchStatus?: number | null,
      pourTime?: number | null,
      setpointTime?: number | null,
      errorCode?: number | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      ltaOnCharger?: number | null,
      ltaOnBottle?: number | null,
      proxDuringLiquidDetect1?: number | null,
      proxDuringLiquidDetect2?: number | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSpoutGen4LiquorQueryVariables = {
  id?: string,
};

export type GetSpoutGen4LiquorQuery = {
  getSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSpoutGen4LiquorsQueryVariables = {
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorsQuery = {
  listSpoutGen4Liquors?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSpoutGen4DeviceQueryVariables = {
  id?: string,
};

export type GetSpoutGen4DeviceQuery = {
  getSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListSpoutGen4DevicesQueryVariables = {
  filter?: ModelSpoutGen4DeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4DevicesQuery = {
  listSpoutGen4Devices?:  {
    __typename: "ModelSpoutGen4DeviceConnection",
    items?:  Array< {
      __typename: "SpoutGen4Device",
      id: string,
      userId: string,
      locationId: string,
      liquorId: string,
      createAt?: string | null,
      updateAt?: string | null,
      engraving?: string | null,
      spoutId?: string | null,
      volume?: number | null,
      onBottle?: boolean | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4EventDataByLocationIdQueryVariables = {
  locationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4EventDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventDataByLocationIdQuery = {
  listSpoutGen4EventDataByLocationId?:  {
    __typename: "ModelSpoutGen4EventDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      eventData?: string | null,
      liquorId: string,
      liquorName?: string | null,
      ttl?: number | null,
      datetimeEventType?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4EventDatByLocationIdDatetimeQueryVariables = {
  locationId?: string | null,
  datetime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4EventDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventDatByLocationIdDatetimeQuery = {
  listSpoutGen4EventDatByLocationIdDatetime?:  {
    __typename: "ModelSpoutGen4EventDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      eventData?: string | null,
      liquorId: string,
      liquorName?: string | null,
      ttl?: number | null,
      datetimeEventType?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4EventDatByLocationIdDatetimeEventTypeQueryVariables = {
  locationId?: string | null,
  datetimeEventType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4EventDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventDatByLocationIdDatetimeEventTypeQuery = {
  listSpoutGen4EventDatByLocationIdDatetimeEventType?:  {
    __typename: "ModelSpoutGen4EventDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      eventData?: string | null,
      liquorId: string,
      liquorName?: string | null,
      ttl?: number | null,
      datetimeEventType?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4EventPourDatByLocationIdDatetimeQueryVariables = {
  locationId?: string | null,
  datetime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4EventPourDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4EventPourDatByLocationIdDatetimeQuery = {
  listSpoutGen4EventPourDatByLocationIdDatetime?:  {
    __typename: "ModelSpoutGen4EventPourDataConnection",
    items?:  Array< {
      __typename: "SpoutGen4EventPourData",
      id: string,
      userId: string,
      locationId: string,
      datetime: string,
      liquorId: string,
      ipadDatetime?: string | null,
      eventId?: number | null,
      spoutId?: string | null,
      engraving?: string | null,
      rsoc?: number | null,
      eventType?: number | null,
      liquorType?: number | null,
      pourVolume?: number | null,
      pourStatus?: number | null,
      volumeRemaining?: number | null,
      totalPourSuccess?: number | null,
      totalPourFailed?: number | null,
      totalPourAmount?: number | null,
      liquorName?: string | null,
      pourSelection?: number | null,
      unitPrice?: number | null,
      volumePrice?: number | null,
      ttl?: number | null,
      setpointVolume?: number | null,
      setpointPrice?: number | null,
      bottleSwitchStatus?: number | null,
      pourTime?: number | null,
      setpointTime?: number | null,
      errorCode?: number | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      ltaOnCharger?: number | null,
      ltaOnBottle?: number | null,
      proxDuringLiquidDetect1?: number | null,
      proxDuringLiquidDetect2?: number | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4LiquorByLocationIdQueryVariables = {
  locationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorByLocationIdQuery = {
  listSpoutGen4LiquorByLocationId?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4LiquorByLocationIdRemainingQueryVariables = {
  locationId?: string | null,
  remaining?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorByLocationIdRemainingQuery = {
  listSpoutGen4LiquorByLocationIdRemaining?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4LiquorByProductIdQueryVariables = {
  productId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorByProductIdQuery = {
  listSpoutGen4LiquorByProductId?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4LiquorByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorByNameQuery = {
  listSpoutGen4LiquorByName?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4LiquorByLocationIdNameQueryVariables = {
  locationId?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4LiquorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4LiquorByLocationIdNameQuery = {
  listSpoutGen4LiquorByLocationIdName?:  {
    __typename: "ModelSpoutGen4LiquorConnection",
    items?:  Array< {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSpoutGen4DeviceByLocationIdQueryVariables = {
  locationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpoutGen4DeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpoutGen4DeviceByLocationIdQuery = {
  listSpoutGen4DeviceByLocationId?:  {
    __typename: "ModelSpoutGen4DeviceConnection",
    items?:  Array< {
      __typename: "SpoutGen4Device",
      id: string,
      userId: string,
      locationId: string,
      liquorId: string,
      createAt?: string | null,
      updateAt?: string | null,
      engraving?: string | null,
      spoutId?: string | null,
      volume?: number | null,
      onBottle?: boolean | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      liquor?:  {
        __typename: "SpoutGen4Liquor",
        id: string,
        accountId: string,
        locationId: string,
        productId: string,
        name?: string | null,
        brand?: string | null,
        thumbnail?: string | null,
        setpoints?: Array< number | null > | null,
        prices?: Array< number | null > | null,
        remaining?: number | null,
        currency?: string | null,
        locationName?: string | null,
        pourtime?: string | null,
        size?: number | null,
        setpointsOz?: Array< number | null > | null,
        cost?: number | null,
        category?: string | null,
        virtualBar?: number | null,
        bottleSensor?: number | null,
        IQSReg05?: number | null,
        IQSReg06?: number | null,
        IQSReg07?: number | null,
        IQSReg08?: number | null,
        IQSReg09?: number | null,
        IQSReg0A?: number | null,
        IQSReg0B?: number | null,
        expDelta?: number | null,
        liquidWait?: number | null,
        createAt?: string | null,
        updateAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateSpoutGen4EventDataSubscription = {
  onCreateSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpoutGen4EventDataSubscription = {
  onUpdateSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSpoutGen4EventDataSubscription = {
  onDeleteSpoutGen4EventData?:  {
    __typename: "SpoutGen4EventData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    eventData?: string | null,
    liquorId: string,
    liquorName?: string | null,
    ttl?: number | null,
    datetimeEventType?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSpoutGen4EventPourDataSubscription = {
  onCreateSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpoutGen4EventPourDataSubscription = {
  onUpdateSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSpoutGen4EventPourDataSubscription = {
  onDeleteSpoutGen4EventPourData?:  {
    __typename: "SpoutGen4EventPourData",
    id: string,
    userId: string,
    locationId: string,
    datetime: string,
    liquorId: string,
    ipadDatetime?: string | null,
    eventId?: number | null,
    spoutId?: string | null,
    engraving?: string | null,
    rsoc?: number | null,
    eventType?: number | null,
    liquorType?: number | null,
    pourVolume?: number | null,
    pourStatus?: number | null,
    volumeRemaining?: number | null,
    totalPourSuccess?: number | null,
    totalPourFailed?: number | null,
    totalPourAmount?: number | null,
    liquorName?: string | null,
    pourSelection?: number | null,
    unitPrice?: number | null,
    volumePrice?: number | null,
    ttl?: number | null,
    setpointVolume?: number | null,
    setpointPrice?: number | null,
    bottleSwitchStatus?: number | null,
    pourTime?: number | null,
    setpointTime?: number | null,
    errorCode?: number | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    ltaOnCharger?: number | null,
    ltaOnBottle?: number | null,
    proxDuringLiquidDetect1?: number | null,
    proxDuringLiquidDetect2?: number | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSpoutGen4LiquorSubscription = {
  onCreateSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpoutGen4LiquorSubscription = {
  onUpdateSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSpoutGen4LiquorSubscription = {
  onDeleteSpoutGen4Liquor?:  {
    __typename: "SpoutGen4Liquor",
    id: string,
    accountId: string,
    locationId: string,
    productId: string,
    name?: string | null,
    brand?: string | null,
    thumbnail?: string | null,
    setpoints?: Array< number | null > | null,
    prices?: Array< number | null > | null,
    remaining?: number | null,
    currency?: string | null,
    locationName?: string | null,
    pourtime?: string | null,
    size?: number | null,
    setpointsOz?: Array< number | null > | null,
    cost?: number | null,
    category?: string | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    IQSReg05?: number | null,
    IQSReg06?: number | null,
    IQSReg07?: number | null,
    IQSReg08?: number | null,
    IQSReg09?: number | null,
    IQSReg0A?: number | null,
    IQSReg0B?: number | null,
    expDelta?: number | null,
    liquidWait?: number | null,
    createAt?: string | null,
    updateAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSpoutGen4DeviceSubscription = {
  onCreateSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateSpoutGen4DeviceSubscription = {
  onUpdateSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteSpoutGen4DeviceSubscription = {
  onDeleteSpoutGen4Device?:  {
    __typename: "SpoutGen4Device",
    id: string,
    userId: string,
    locationId: string,
    liquorId: string,
    createAt?: string | null,
    updateAt?: string | null,
    engraving?: string | null,
    spoutId?: string | null,
    volume?: number | null,
    onBottle?: boolean | null,
    virtualBar?: number | null,
    bottleSensor?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    liquor?:  {
      __typename: "SpoutGen4Liquor",
      id: string,
      accountId: string,
      locationId: string,
      productId: string,
      name?: string | null,
      brand?: string | null,
      thumbnail?: string | null,
      setpoints?: Array< number | null > | null,
      prices?: Array< number | null > | null,
      remaining?: number | null,
      currency?: string | null,
      locationName?: string | null,
      pourtime?: string | null,
      size?: number | null,
      setpointsOz?: Array< number | null > | null,
      cost?: number | null,
      category?: string | null,
      virtualBar?: number | null,
      bottleSensor?: number | null,
      IQSReg05?: number | null,
      IQSReg06?: number | null,
      IQSReg07?: number | null,
      IQSReg08?: number | null,
      IQSReg09?: number | null,
      IQSReg0A?: number | null,
      IQSReg0B?: number | null,
      expDelta?: number | null,
      liquidWait?: number | null,
      createAt?: string | null,
      updateAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};
