import React, {useState, useCallback, useContext} from "react";
import {DayPickerRangeController} from "react-dates";
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import LocationContext from "../contexts/LocationContext";
import moment from "moment";
import styles from "./ReportDialog.module.css";
import {
    customDayStyles,
    CustomNavPrev,
    CustomNavNext
} from "./DateRangeSelector.styles";

export default function ReportDialog({isOpen, location, onRequestClose}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState("startDate");

    const loc = useContext(LocationContext);

    const handleFocusChange = useCallback(focusedInput => {
        // Force the focusedInput to always be truthy so that dates are always selectable
        setFocusedInput(focusedInput || "startDate");
    }, []);
    const handleDatesChange = useCallback(({startDate, endDate}) => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
            const tmp = startDate;
            startDate = endDate;
            endDate = tmp;
        }
        setStartDate(startDate);
        setEndDate(endDate);
    }, []);
    const handleClickDownload = useCallback(e => {
        if (!startDate || !endDate) {
            e.preventDefault();
            return;
        }
        onRequestClose();
    }, [startDate, endDate, onRequestClose]);

    const reportToken = loc.byId[location].reportToken;
    const downloadUrl = startDate && endDate ? `/api/report?reportToken=${reportToken}&locationId=${location}&startDateTime=${moment(startDate).startOf("day").format()}&endDateTime=${moment(endDate).endOf("day").format()}` : "#";

    const renderControls = () => {
        return (
            <section className="calendar_controls">
                {startDate && endDate ?
                    <div className={styles.dateRange}>
                        <div>{startDate.format("ll")}</div>
                        <div>To</div>
                        <div>{endDate.format("ll")}</div>
                    </div>
                    :
                    <div className={styles.dateRange}>Please select a date range.</div>
                }
                <div className="action">
                    <a className={startDate && endDate ? "" : "disabled"} href={downloadUrl} 
                       onClick={handleClickDownload} target="_blank" rel="noreferrer">Download</a>
                </div>
            </section>
        );
    };

    return (
        <div className={styles.root}>
            {isOpen && <div className={styles.backdrop}>
                <div className={styles.anchor}>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <DayPickerRangeController
                                navPrev={<CustomNavPrev/>}
                                navNext={<CustomNavNext/>}
                                minimumNights={0}
                                onFocusChange={handleFocusChange}
                                onDatesChange={handleDatesChange}
                                focusedInput={focusedInput}
                                startDate={startDate}
                                endDate={endDate}
                                keepOpenOnDateSelect={false}
                                enableOutsideDays={false}
                                hideKeyboardShortcutsPanel={true}
                                numberOfMonths={1}
                                monthFormat="MMMM"
                                onOutsideClick={onRequestClose}
                                renderCalendarDay={props => (
                                    <CustomizableCalendarDay {...props} {...customDayStyles} />
                                )}
                                renderCalendarInfo={renderControls}
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}