import {useRef} from "react";

export default function useImmediate(fn, deps) {
    const first = useRef(true);
    const ref = useRef(deps);
    
    if (first.current) {
        first.current = false;
        return fn();
    }
    else {
        for(let i = 0; i < deps.length; i++) {
            if (ref.current[i] !== deps[i]) {
                ref.current = deps;
                return fn();
            }
        }
    }
    ref.current = deps;
}
