/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSpoutGen4EventData = /* GraphQL */ `
  mutation CreateSpoutGen4EventData(
    $input: CreateSpoutGen4EventDataInput!
    $condition: ModelSpoutGen4EventDataConditionInput
  ) {
    createSpoutGen4EventData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      eventData
      liquorId
      liquorName
      ttl
      datetimeEventType
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSpoutGen4EventData = /* GraphQL */ `
  mutation UpdateSpoutGen4EventData(
    $input: UpdateSpoutGen4EventDataInput!
    $condition: ModelSpoutGen4EventDataConditionInput
  ) {
    updateSpoutGen4EventData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      eventData
      liquorId
      liquorName
      ttl
      datetimeEventType
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpoutGen4EventData = /* GraphQL */ `
  mutation DeleteSpoutGen4EventData(
    $input: DeleteSpoutGen4EventDataInput!
    $condition: ModelSpoutGen4EventDataConditionInput
  ) {
    deleteSpoutGen4EventData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      eventData
      liquorId
      liquorName
      ttl
      datetimeEventType
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpoutGen4EventPourData = /* GraphQL */ `
  mutation CreateSpoutGen4EventPourData(
    $input: CreateSpoutGen4EventPourDataInput!
    $condition: ModelSpoutGen4EventPourDataConditionInput
  ) {
    createSpoutGen4EventPourData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      liquorId
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      liquorType
      pourVolume
      pourStatus
      volumeRemaining
      totalPourSuccess
      totalPourFailed
      totalPourAmount
      liquorName
      pourSelection
      unitPrice
      volumePrice
      ttl
      setpointVolume
      setpointPrice
      bottleSwitchStatus
      pourTime
      setpointTime
      errorCode
      cost
      category
      virtualBar
      ltaOnCharger
      ltaOnBottle
      proxDuringLiquidDetect1
      proxDuringLiquidDetect2
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSpoutGen4EventPourData = /* GraphQL */ `
  mutation UpdateSpoutGen4EventPourData(
    $input: UpdateSpoutGen4EventPourDataInput!
    $condition: ModelSpoutGen4EventPourDataConditionInput
  ) {
    updateSpoutGen4EventPourData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      liquorId
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      liquorType
      pourVolume
      pourStatus
      volumeRemaining
      totalPourSuccess
      totalPourFailed
      totalPourAmount
      liquorName
      pourSelection
      unitPrice
      volumePrice
      ttl
      setpointVolume
      setpointPrice
      bottleSwitchStatus
      pourTime
      setpointTime
      errorCode
      cost
      category
      virtualBar
      ltaOnCharger
      ltaOnBottle
      proxDuringLiquidDetect1
      proxDuringLiquidDetect2
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpoutGen4EventPourData = /* GraphQL */ `
  mutation DeleteSpoutGen4EventPourData(
    $input: DeleteSpoutGen4EventPourDataInput!
    $condition: ModelSpoutGen4EventPourDataConditionInput
  ) {
    deleteSpoutGen4EventPourData(input: $input, condition: $condition) {
      id
      userId
      locationId
      datetime
      liquorId
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      liquorType
      pourVolume
      pourStatus
      volumeRemaining
      totalPourSuccess
      totalPourFailed
      totalPourAmount
      liquorName
      pourSelection
      unitPrice
      volumePrice
      ttl
      setpointVolume
      setpointPrice
      bottleSwitchStatus
      pourTime
      setpointTime
      errorCode
      cost
      category
      virtualBar
      ltaOnCharger
      ltaOnBottle
      proxDuringLiquidDetect1
      proxDuringLiquidDetect2
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpoutGen4Liquor = /* GraphQL */ `
  mutation CreateSpoutGen4Liquor(
    $input: CreateSpoutGen4LiquorInput!
    $condition: ModelSpoutGen4LiquorConditionInput
  ) {
    createSpoutGen4Liquor(input: $input, condition: $condition) {
      id
      accountId
      locationId
      productId
      name
      brand
      thumbnail
      setpoints
      prices
      remaining
      currency
      locationName
      pourtime
      size
      setpointsOz
      cost
      category
      virtualBar
      bottleSensor
      IQSReg05
      IQSReg06
      IQSReg07
      IQSReg08
      IQSReg09
      IQSReg0A
      IQSReg0B
      expDelta
      liquidWait
      createAt
      updateAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSpoutGen4Liquor = /* GraphQL */ `
  mutation UpdateSpoutGen4Liquor(
    $input: UpdateSpoutGen4LiquorInput!
    $condition: ModelSpoutGen4LiquorConditionInput
  ) {
    updateSpoutGen4Liquor(input: $input, condition: $condition) {
      id
      accountId
      locationId
      productId
      name
      brand
      thumbnail
      setpoints
      prices
      remaining
      currency
      locationName
      pourtime
      size
      setpointsOz
      cost
      category
      virtualBar
      bottleSensor
      IQSReg05
      IQSReg06
      IQSReg07
      IQSReg08
      IQSReg09
      IQSReg0A
      IQSReg0B
      expDelta
      liquidWait
      createAt
      updateAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpoutGen4Liquor = /* GraphQL */ `
  mutation DeleteSpoutGen4Liquor(
    $input: DeleteSpoutGen4LiquorInput!
    $condition: ModelSpoutGen4LiquorConditionInput
  ) {
    deleteSpoutGen4Liquor(input: $input, condition: $condition) {
      id
      accountId
      locationId
      productId
      name
      brand
      thumbnail
      setpoints
      prices
      remaining
      currency
      locationName
      pourtime
      size
      setpointsOz
      cost
      category
      virtualBar
      bottleSensor
      IQSReg05
      IQSReg06
      IQSReg07
      IQSReg08
      IQSReg09
      IQSReg0A
      IQSReg0B
      expDelta
      liquidWait
      createAt
      updateAt
      createdAt
      updatedAt
    }
  }
`;
export const createSpoutGen4Device = /* GraphQL */ `
  mutation CreateSpoutGen4Device(
    $input: CreateSpoutGen4DeviceInput!
    $condition: ModelSpoutGen4DeviceConditionInput
  ) {
    createSpoutGen4Device(input: $input, condition: $condition) {
      id
      userId
      locationId
      liquorId
      createAt
      updateAt
      engraving
      spoutId
      volume
      onBottle
      virtualBar
      bottleSensor
      createdAt
      updatedAt
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSpoutGen4Device = /* GraphQL */ `
  mutation UpdateSpoutGen4Device(
    $input: UpdateSpoutGen4DeviceInput!
    $condition: ModelSpoutGen4DeviceConditionInput
  ) {
    updateSpoutGen4Device(input: $input, condition: $condition) {
      id
      userId
      locationId
      liquorId
      createAt
      updateAt
      engraving
      spoutId
      volume
      onBottle
      virtualBar
      bottleSensor
      createdAt
      updatedAt
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSpoutGen4Device = /* GraphQL */ `
  mutation DeleteSpoutGen4Device(
    $input: DeleteSpoutGen4DeviceInput!
    $condition: ModelSpoutGen4DeviceConditionInput
  ) {
    deleteSpoutGen4Device(input: $input, condition: $condition) {
      id
      userId
      locationId
      liquorId
      createAt
      updateAt
      engraving
      spoutId
      volume
      onBottle
      virtualBar
      bottleSensor
      createdAt
      updatedAt
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
    }
  }
`;
