import { useRef, useEffect } from "react";

export default function useAnimationFrame(fn, deps) {
    const frame = useRef();
    
    useEffect(() => {
        function animate() {
            fn();
            frame.current = requestAnimationFrame(animate);
        }
        
        frame.current = requestAnimationFrame(animate);
        return () => {
            cancelAnimationFrame(frame.current);
        };
        // eslint-disable-next-line
    }, [...deps]);
}
