import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import LocationContext from "../contexts/LocationContext";
import styles from "./LiveViewPage.module.css";
import panelStyles from "../components/PanelLayout.module.css";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import DropDown from "../components/DropDown";
import useStorageState from "../hooks/useStorageState";
import Panel from "../components/Panel";
import Table from "../components/Table";
import Gen4GraphqlContext from "../contexts/Gen4GraphqlContext";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import { DayPickerSingleDateController } from "react-dates";
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import {
    customDayStyles,
    CustomNavPrev,
    CustomNavNext,
} from "../components/DateRangeSelector.styles";

const MOTOR_STALL = 2;
const POURTIME_THRESHOLD = 10;

export default function LiveViewPage({ config }) {
    const PAGESIZE = 12;
    const loc = useContext(LocationContext);
    let timer = null;
    const gen4Graphql = useContext(Gen4GraphqlContext);
    const [mode, setMode] = useStorageState("mode", "overview");
    const [location, setLocation] = useStorageState(
        "location",
        loc.flat[0].locationId
    );

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [displayItems, setDisplayItems] = useState(null);
    const [selectedDate, setSelectedDate] = useState(
        moment().tz(loc.byId[location].timezone ?? "UTC")
    );
    const [focusedInput, setFocusedInput] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [queryToken, setQueryToken] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isOldVersion, setIsOldVersion] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);

    const latestItemDateRef = useRef("");

    const handleOpenSettings = useCallback(() => setIsSettingsOpen(true), []);
    const handleCloseSettings = useCallback(() => setIsSettingsOpen(false), []);
    const handleSetLocation = useCallback((v) => setLocation(v), [setLocation]);

    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleClickLocation = useCallback(
        (e) => {
            handleSetLocation(e.target.value);
            setIsOpen(false);
        },
        [handleSetLocation]
    );
    const handleSingleDatePickerFocus = useCallback(({ focused }) => {
        setFocusedInput(focused);
        if (!focused) {
            setIsDatePickerOpen(false);
        }
    }, []);
    const handleDatesChange = useCallback((selectedDate) => {
        clearTimeout(timer);
        setFocusedInput(false);
        setIsDatePickerOpen(false);

        setPage(1);
        setItems([]);
        setIsLoading(true);
        setQueryToken(null);
        setNextPageToken(null);
        setSelectedDate(selectedDate);
        setIsOldVersion(false);
        latestItemDateRef.current = "";
    }, []);
    const handleDateClick = useCallback(() => setIsDatePickerOpen(true), []);
    const onRequestClose = useCallback(() => setIsDatePickerOpen(false), []);
    const handleNextPageClick = useCallback(() => {
        let newPage = page + 1;
        const display = items.slice(
            (newPage - 1) * PAGESIZE,
            newPage * PAGESIZE
        );
        clearTimeout(timer);
        setPage(newPage);
        setHasPreviousPage(true);

        if (display.length < PAGESIZE) {
            if (nextPageToken != null) {
                setQueryToken(nextPageToken);
            } else {
                setHasNextPage(false);
                setDisplayItems(display);
            }
        } else {
            setDisplayItems(display);
        }
    }, [nextPageToken, items, page, timer]);

    const handlePreviousPageClick = useCallback(() => {
        let newPage = page - 1;
        const display = items.slice(
            (newPage - 1) * PAGESIZE,
            newPage * PAGESIZE
        );
        clearTimeout(timer);
        setPage(newPage);
        setDisplayItems(display);
        setHasNextPage(true);
        if (newPage === 1) {
            setHasPreviousPage(false);
        }
    }, [items, page, timer]);

    const title = useMemo(() => {
        return `${loc.root.name} - ${loc.byId[location]?.name}`;
    }, [location, loc]);
    const locationList = useMemo(
        () =>
            loc.root.locations.map(({ level, locationId, name }) => (
                <button
                    key={locationId}
                    value={locationId}
                    className={`${level > 0 ? styles.indent : ""} ${location === locationId ? styles.active : ""
                        }`}
                    onClick={handleClickLocation}
                >
                    {`${loc.root.name} - ${name}`}
                </button>
            )),
        [loc, location, handleClickLocation]
    );
    useEffect(() => {
        setMode("overview");
    }, []);

    useEffect(() => {
        const display = items.slice((page - 1) * PAGESIZE, page * PAGESIZE);
        setDisplayItems(display);
        if (display.length < PAGESIZE) {
            setHasNextPage(false);
        } else {
            setHasNextPage(true);
        }
    }, [items]);

    useEffect(() => {
        let cancel = false;
        let prevDataStr = "";
        fetch(false);

        return () => {
            cancel = true;
            clearTimeout(timer);
        };
    }, [location, selectedDate, queryToken]);

    async function fetch(isTimeout = false) {
        setIsLoading(true);

        const milliSec = Math.floor(Math.random() * 500);
        let timezone = loc.byId[location].timezone ?? loc.root.timezone ?? "UTC";
        var start = selectedDate
            .tz(timezone)
            .startOf("d")
            .add(milliSec, "ms")
            .toISOString(true);
        if (isTimeout) {
            start = latestItemDateRef.current
                ? moment(latestItemDateRef.current)
                    .tz(timezone)
                    .add(milliSec, "ms")
                    .toISOString(true)
                : selectedDate
                    .tz(timezone)
                    .startOf("d")
                    .add(milliSec, "ms")
                    .toISOString(true);
        }
        const end = selectedDate.tz(timezone).endOf("d").toISOString(true);
        var items = [];
        var token = null;
        let latestItemDate = "";
        const limit = 120;
        do {
            const dynamodbItem = await gen4Graphql.listSpoutGen4PourEventData(
                location,
                start,
                end,
                limit,
                isTimeout ? null : queryToken
            );

            if (dynamodbItem.items.length === 0) {
                break;
            }
            debugger;
            let data = dynamodbItem.items;
            let onBottle = data.filter(
                (d) =>
                (((d.eventType === 1) &&
                    (((d.errorCode & MOTOR_STALL) === 0) ||
                        (d.valveOpenTime1 > POURTIME_THRESHOLD))))
            );

            if (!latestItemDate) {
                latestItemDate = data[0].datetime;
            }
            items = items.concat(onBottle);
            token = dynamodbItem.nextToken;
        } while (items.length < limit && token !== null);

        if (!isTimeout) {
            setNextPageToken(token);
            if (!latestItemDateRef.current) {
                latestItemDateRef.current = latestItemDate;
            }
        } else {
            if (latestItemDate !== "") {
                latestItemDateRef.current = latestItemDate;
            }
        }
        setIsLoading(false);
        if (items.length > 0) {
            setItems((oldArray) => {
                return [...oldArray, ...items];
            });
        }

        timer = setTimeout(() => fetch(true), 30 * 1000);
    }

    const tableData = {
        columns: [
            {
                title: "ID",
                key: "id",
                render: (row, column, index) =>
                    (page - 1) * PAGESIZE + (index + 1),
            },
            {
                title: "Timestamp",
                key: "datetime",
                align: "center",
                render: (row, column) => {
                    let timezone = loc.byId[location].timezone ?? loc.root.timezone ?? "UTC";
                    return moment.tz(row[column.key], timezone).format("MMMM DD YYYY, h:mm:ss a")
                },
            },
            {
                title: "Liquor",
                key: "liquorName",
                align: "start",
                cAlign: "start",
            },
            { title: "Spout ID", key: "engraving", align: "center" },
            {
                title: "Battery",
                key: "rsoc",
                align: "center",
                render: (row, column) => {
                    let battery = row[column.key];
                    if (battery >= 50) {
                        return (
                            <div>
                                <svg
                                    id="color"
                                    enableBackground="new 0 0 24 24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z"
                                        fill="#607d8b"
                                    />
                                    <path
                                        d="m2.75 5.5c-.689 0-1.25.561-1.25 1.25v9.5c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25v-5.5c0-.138-.112-.25-.25-.25h-.991c-.414 0-.75-.336-.75-.75v-1c0-.689-.561-1.25-1.25-1.25z"
                                        fill="#fafafa"
                                    />
                                    <path
                                        d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.561-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z"
                                        fill="#546d79"
                                    />
                                    <path
                                        d="m22.491 11.5h-3.491v3.75c0 .414-.336.75-.75.75h-14.5c-.414 0-.75-.336-.75-.75v-3.75h-1.5v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"
                                        fill="#dadada"
                                    />
                                    <path
                                        d="m18.25 16h-14.5c-.414 0-.75-.336-.75-.75v-7.5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75v7.5c0 .414-.336.75-.75.75z"
                                        fill="#4caf50"
                                    />
                                    <path
                                        d="m11 7h-7.25c-.414 0-.75.336-.75.75v7.5c0 .414.336.75.75.75h7.25z"
                                        fill="#429846"
                                    />
                                </svg>
                            </div>
                        );
                    }
                    if (battery >= 20) {
                        return (
                            <div>
                                <svg
                                    id="color"
                                    enableBackground="new 0 0 24 24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z"
                                        fill="#607d8b"
                                    />
                                    <path
                                        d="m2.75 5.5c-.689 0-1.25.561-1.25 1.25v9.5c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25v-5.5c0-.138-.112-.25-.25-.25h-.991c-.414 0-.75-.336-.75-.75v-1c0-.689-.561-1.25-1.25-1.25z"
                                        fill="#fafafa"
                                    />
                                    <path
                                        d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.561-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z"
                                        fill="#546d79"
                                    />
                                    <path
                                        d="m22.491 11.5h-9.491v3.75c0 .414-.336.75-.75.75h-8.5c-.414 0-.75-.336-.75-.75v-3.75h-1.5v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"
                                        fill="#dadada"
                                    />
                                    <path
                                        d="m12.25 16h-8.5c-.414 0-.75-.336-.75-.75v-7.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v7.5c0 .414-.336.75-.75.75z"
                                        fill="#ffc107"
                                    />
                                    <path
                                        d="m8 7h-4.25c-.414 0-.75.336-.75.75v7.5c0 .414.336.75.75.75h4.25z"
                                        fill="#dea806"
                                    />
                                </svg>
                            </div>
                        );
                    }
                    if (battery >= 10) {
                        return (
                            <div>
                                <svg
                                    id="color"
                                    enableBackground="new 0 0 24 24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z"
                                        fill="#607d8b"
                                    />
                                    <path
                                        d="m2.75 5.5c-.689 0-1.25.561-1.25 1.25v9.5c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25v-5.5c0-.138-.112-.25-.25-.25h-.991c-.414 0-.75-.336-.75-.75v-1c0-.689-.561-1.25-1.25-1.25z"
                                        fill="#fafafa"
                                    />
                                    <path
                                        d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.561-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z"
                                        fill="#546d79"
                                    />
                                    <path
                                        d="m22.491 11.5h-14.491v3.75c0 .414-.336.75-.75.75h-3.5c-.414 0-.75-.336-.75-.75v-3.75h-1.5v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"
                                        fill="#dadada"
                                    />
                                    <path
                                        d="m7.25 16h-3.5c-.414 0-.75-.336-.75-.75v-7.5c0-.414.336-.75.75-.75h3.5c.414 0 .75.336.75.75v7.5c0 .414-.336.75-.75.75z"
                                        fill="#f44336"
                                    />
                                    <path
                                        d="m5.5 7h-1.75c-.414 0-.75.336-.75.75v7.5c0 .414.336.75.75.75h1.75z"
                                        fill="#d43a2f"
                                    />
                                </svg>
                            </div>
                        );
                    }
                    if (battery >= 5) {
                        return (
                            <div>
                                <svg
                                    id="color"
                                    enableBackground="new 0 0 24 24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z"
                                        fill="#607d8b"
                                    />
                                    <path
                                        d="m2.75 5.5c-.689 0-1.25.561-1.25 1.25v9.5c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25v-5.5c0-.138-.112-.25-.25-.25h-.991c-.414 0-.75-.336-.75-.75v-1c0-.689-.561-1.25-1.25-1.25z"
                                        fill="#fafafa"
                                    />
                                    <path
                                        d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.561-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z"
                                        fill="#546d79"
                                    />
                                    <path
                                        d="m22.491 11.5h-17.991v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75h-1.5v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"
                                        fill="#dadada"
                                    />
                                    <path
                                        d="m3.75 16c-.414 0-.75-.336-.75-.75v-7.5c0-.414.336-.75.75-.75s.75.336.75.75v7.5c0 .414-.336.75-.75.75z"
                                        fill="#f44336"
                                    />
                                    <path
                                        d="m3.75 7c-.414 0-.75.336-.75.75v7.5c0 .414.336.75.75.75z"
                                        fill="#d43a2f"
                                    />
                                </svg>
                            </div>
                        );
                    }
                    if (battery < 5) {
                        return (
                            <div>
                                <svg
                                    id="color"
                                    enableBackground="new 0 0 24 24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z"
                                        fill="#607d8b"
                                    />
                                    <path
                                        d="m2.75 5.5c-.689 0-1.25.56-1.25 1.25v9.5c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.56 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25v-5.5c0-.138-.112-.25-.25-.25h-.991c-.414 0-.75-.336-.75-.75v-1c0-.689-.561-1.25-1.25-1.25z"
                                        fill="#fafafa"
                                    />
                                    <path
                                        d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.56-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z"
                                        fill="#546d79"
                                    />
                                    <path
                                        d="m22.491 11.5h-20.991v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.56 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"
                                        fill="#dadada"
                                    />
                                    <path
                                        d="m1 24c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l22-22c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-22 22c-.195.195-.451.293-.707.293z"
                                        fill="#f44336"
                                    />
                                    <path
                                        d="m23.707.293-23.414 23.414c.195.195.451.293.707.293s.512-.098.707-.293l22-22c.391-.391.391-1.023 0-1.414z"
                                        fill="#d43a2f"
                                    />
                                </svg>
                            </div>
                        );
                    }
                    return battery;
                },
            },
            {
                title: "Pour Size",
                key: "pourSelection",
                align: "center",
                render: (row, column) => {
                    const size = row[column.key];
                    debugger
                    if (size == undefined) {
                        return "-";
                    }
                    switch (size) {
                        case 1:
                            return (
                                <div style={{ color: "#99989A" }}>{size}</div>
                            );
                        case 2:
                            return (
                                <div style={{ color: "#EB9739" }}>{size}</div>
                            );
                        case 3:
                            return (
                                <div style={{ color: "#607E5C" }}>{size}</div>
                            );
                        case 4:
                            return (
                                <div style={{ color: "#8080FF" }}>{size}</div>
                            );
                        default:
                            return <div>{size}</div>;
                            break;
                    }
                },
            },
            {
                title: "Volume (oz.)",
                key: "pourVolume",
                align: "center",
                render: (row, column) => {
                    const pourVolume = row[column.key];
                    if (pourVolume == undefined) {
                        return "-";
                    }
                    return `${pourVolume.toFixed(2)}`;
                },
            },
            {
                title: "Price ($)",
                key: "volumePrice",
                align: "end",
                render: (row, column) => {
                    const price = row[column.key];
                    if (price == undefined) {
                        return "-";
                    }
                    return `$ ${price.toFixed(2)}`;
                },
            },
            {
                title: "Virtual Bar",
                key: "virtualBar",
                align: "center",
                render: (row, column) => `VB 1`,
            },
        ],
    };

    const header = (
        <div className={styles.header}>
            <DropDown
                activator={
                    <div className={styles.title} onClick={handleOpen}>
                        {title}
                    </div>
                }
                onRequestClose={handleClose}
                isOpen={isOpen}
                offsetX="11px"
                offsetY="16px"
            >
                <div className={styles.aggregationList}>{locationList}</div>
            </DropDown>

            <div>
                <button onClick={handleDateClick}>
                    <div className={styles.choice}>
                        {selectedDate.format("ll")}
                    </div>
                </button>
                <div className={styles.anchor}>
                    {isDatePickerOpen && (
                        <div className={styles.container}>
                            <div className={styles.content}>
                                <DayPickerSingleDateController
                                    date={selectedDate} // momentPropTypes.momentObj or null
                                    onDateChange={handleDatesChange} // PropTypes.func.isRequired
                                    focused={true} // PropTypes.bool
                                    onFocusChange={handleSingleDatePickerFocus} // PropTypes.func.isRequired
                                    // id="your_unique_id" // PropTypes.string.isRequired,
                                    keepOpenOnDateSelect={true}
                                    enableOutsideDays={false}
                                    hideKeyboardShortcutsPanel={true}
                                    numberOfMonths={1}
                                    monthFormat="MMMM"
                                    renderCalendarDay={(props) => (
                                        <CustomizableCalendarDay
                                            {...props}
                                            {...customDayStyles}
                                        />
                                    )}
                                    navPrev={<CustomNavPrev />}
                                    navNext={<CustomNavNext />}
                                    onOutsideClick={onRequestClose}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const PanelLayout = () => {
        return (
            <div
                key={"onePanel"}
                className={`${panelStyles.layout} ${panelStyles.onePanel}`}
            >
                <Panel header={header}>
                    {isLoading && <Loader />}
                    {!isLoading && displayItems != null && (
                        <div className={styles.table}>
                            <Table
                                columns={tableData.columns}
                                rows={displayItems}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginRight: "10px",
                                    width: "90%",
                                    alignSelf: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                <div className="action" style={{}}>
                                    <button
                                        className="action"
                                        disabled={!hasPreviousPage}
                                        onClick={handlePreviousPageClick}
                                        style={{
                                            padding: "0 13px",
                                            borderRadius: "15px 0 0 15px",
                                        }}
                                    >
                                        {"<"}
                                    </button>
                                    <button
                                        className="action"
                                        disabled={!hasNextPage}
                                        onClick={handleNextPageClick}
                                        style={{
                                            padding: "0 13px",
                                            borderRadius: "0 15px 15px 0 ",
                                        }}
                                    >
                                        {">"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Panel>
            </div>
        );
    };

    return (
        <>
            <div className={styles.page}>
                <Header
                    onClickSettings={handleOpenSettings}
                    env={config.envName}
                />
                <PanelLayout />
            </div>
            <Sidebar
                isOpen={isSettingsOpen}
                onRequestClose={handleCloseSettings}
                onSetLocation={handleSetLocation}
                location={location}
            />
        </>
    );
}
