import React, {useState, useMemo, useCallback, useContext} from "react";
import LocationContext from "../contexts/LocationContext";
import DropDown from "./DropDown";
import styles from "./AggregationSelector.module.css";

export default function AggregationSelector({
                                                aggregations,
                                                aggregation,
                                                location,
                                                withLocations,
                                                onSetAggregation,
                                                onSetLocation
                                            }) {
    const loc = useContext(LocationContext);

    const [isOpen, setIsOpen] = useState(false);

    const title = useMemo(() => {
        const agg = aggregations.find(i => i.key === aggregation)?.text;
        if (withLocations) {
            return `${agg} - ${loc.byId[location]?.name}`;
        }
        return agg;
    }, [aggregations, aggregation, location, withLocations, loc]);

    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleClickAggregation = useCallback(e => {
        onSetAggregation(e.target.value);
        setIsOpen(false);
    }, [onSetAggregation]);
    const handleClickLocation = useCallback(e => {
        onSetLocation(e.target.value)
        setIsOpen(false);
    }, [onSetLocation]);

    const aggregationList = useMemo(() => aggregations.map(({key, text}) => (
        <button key={key} value={key} className={aggregation === key ? styles.active : ""}
                onClick={handleClickAggregation}>{text}</button>
    )), [aggregations, aggregation, handleClickAggregation]);

    const locationList = useMemo(() => loc.flat.filter(l => l.level === 1).map(({level, locationId, name}) => (
        <button key={locationId} value={locationId}
                className={`${level > 0 ? styles.indent : ""} ${location === locationId ? styles.active : ""}`}
                onClick={handleClickLocation}>
            {name}</button>
    )), [loc, location, handleClickLocation]);

    return (
        <DropDown activator={<div className={styles.title} onClick={handleOpen}>{title}</div>}
                  onRequestClose={handleClose} isOpen={isOpen} offsetX="11px"
                  offsetY="16px">
            {withLocations &&
            <div className={`${styles.aggregationList} ${styles.withLocation}`}>
                <div>
                    {aggregationList}
                </div>
                <div>
                    {locationList}
                </div>
            </div>
            }
            {!withLocations &&
            <div className={styles.aggregationList}>
                {aggregationList}
            </div>
            }
        </DropDown>
    );
}