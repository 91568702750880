import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import LiveViewPage from "./pages/LiveViewPage";
import PoursPerMinutePage from "./pages/PoursPerMinutePage";
import LiquorConpositePage from "./pages/LiquorCompositeViewPage";
import LiquorPage from "./pages/LiquorPage";
import { AuthWrapper } from "./contexts/AuthContext";
import { ApiWrapper } from "./contexts/ApiContext";
import { Gen4GraphqlProvider } from "./contexts/Gen4GraphqlContext";
import { LocationWrapper } from "./contexts/LocationContext";

export default function App({ config }) {
    return (
        <AuthWrapper config={config.auth}>
            <ApiWrapper>
                <LocationWrapper>
                    <Gen4GraphqlProvider>
                        <Router>
                            <Switch>
                                <Route path="/" exact>
                                    <HomePage config={config} />
                                </Route>
                                <Route path="/pours-per-minute" exact>
                                    <PoursPerMinutePage config={config} />
                                </Route>
                                <Route path="/live-view" exact>
                                    <LiveViewPage config={config} />
                                </Route>
                                <Route path="/reports" exact>
                                    <LiquorConpositePage config={config} />
                                </Route>
                                <Route path="/liquors" exact>
                                    <LiquorPage config={config}/>
                                </Route>
                                <Route path="*">
                                    <NotFoundPage />
                                </Route>
                            </Switch>
                        </Router>
                    </Gen4GraphqlProvider>
                </LocationWrapper>
            </ApiWrapper>
        </AuthWrapper>
    );
}
