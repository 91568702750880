// tslint:disable
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      accountId
      locationId
      name
      brand
      ean13
      upc
      thumbnail
      category
      size
      unitOfSize
      price
      currency
      inventoies {
        items {
          id
          productId
          locationId
          stock
        }
        nextToken
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      accountId
      locationId
      name
      brand
      ean13
      upc
      thumbnail
      category
      size
      unitOfSize
      price
      currency
      inventoies {
        items {
          id
          productId
          locationId
          stock
        }
        nextToken
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      accountId
      locationId
      name
      brand
      ean13
      upc
      thumbnail
      category
      size
      unitOfSize
      price
      currency
      inventoies {
        items {
          id
          productId
          locationId
          stock
        }
        nextToken
      }
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      id
      productId
      locationId
      stock
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      id
      productId
      locationId
      stock
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      id
      productId
      locationId
      stock
    }
  }
`;
