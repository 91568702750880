import React from "react";
import Auth from "@aws-amplify/auth";
import gql from "graphql-tag";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsconfig from "../config/aws-exports";
import {
    listSpoutGen4EventDatByLocationIdDatetimeEventType,
    listSpoutGen4EventPourDatByLocationIdDatetime,
    listSpoutGen4LiquorByLocationId,
} from "../utils/gen4/graphql/queries";
import {
    ListSpoutGen4LiquorByLocationIdQuery,
    ListSpoutGen4LiquorByLocationIdQueryVariables,
} from "../utils/gen4/API";

const client = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    },
    disableOffline: true,
});

// Convenience interface for making different types of calls to the API.
interface IGen4Graphql {
    listSpoutGen4PourEventData: any;
    listSpoutGen4EventData: (
        locationId: string,
        startDate: string,
        endDate: string,
        limit: number,
        nextToken: string | null
    ) => void;
    listLiquor: (
        locationId: string,
        limit: number,
        nextToken?: string | null | undefined
    ) => Promise<ListSpoutGen4LiquorByLocationIdQuery>
}

const Gen4Graphql: IGen4Graphql = {
    listSpoutGen4PourEventData(
        locationId: string,
        startDate: string,
        enddate: string,
        limit = 20,
        nextToken = null
    ) {
        return (async function () {
            let result = await client.query<any>({
                query: gql(listSpoutGen4EventPourDatByLocationIdDatetime),
                variables: {
                    locationId: locationId,
                    datetime: {
                        between: [startDate, enddate],
                    },
                    limit: limit,
                    nextToken: nextToken,
                    sortDirection: "DESC",
                },
            });
            return result.data.listSpoutGen4EventPourDatByLocationIdDatetime;
        })();
    },
    listSpoutGen4EventData(
        locationId: string,
        startDate: string,
        endDate: string,
        limit = 20,
        nextToken = null
    ) {
        return (async function () {
            const startDateEventType = `${startDate}#1`;
            const endDateEventType = `${endDate}#2`;
            let result = await client.query<any>({
                query: gql(listSpoutGen4EventDatByLocationIdDatetimeEventType),
                variables: {
                    locationId: locationId,
                    datetimeEventType: {
                        between: [startDateEventType, endDateEventType],
                    },
                    // filter,
                    limit: limit,
                    nextToken: nextToken,
                    sortDirection: "DESC",
                },
            });
            return result.data
                .listSpoutGen4EventDatByLocationIdDatetimeEventType;
        })();
    },
    listLiquor: async (
        locationId: string,
        limit: number = 20,
        nextToken?: string | null | undefined,
    ): Promise<ListSpoutGen4LiquorByLocationIdQuery>  => {
        let result = await client.query<
            ListSpoutGen4LiquorByLocationIdQuery,
            ListSpoutGen4LiquorByLocationIdQueryVariables
        >({
            query: gql(listSpoutGen4LiquorByLocationId),
            fetchPolicy: 'network-only',
            variables: {
                locationId: locationId,
                nextToken: nextToken,
                limit: limit,
            },
        });
        return result.data;
    },
};

const Gen4GraphqlContext = React.createContext<IGen4Graphql | null>(null);

const Gen4GraphqlProvider: React.FC = ({ children }) => {
    return (
        <Gen4GraphqlContext.Provider value={Gen4Graphql}>
            {children}
        </Gen4GraphqlContext.Provider>
    );
};
export { Gen4GraphqlProvider };
export default Gen4GraphqlContext;
