import React, { useState, useContext, useMemo, useEffect } from "react";
import Loader from "./Loader";
import ApiContext from "../contexts/ApiContext";
import LocationContext from "../contexts/LocationContext";
import styles from "./ChartLoader.module.css";
import ResponsiveComponent from "./ResponsiveComponent";
import moment from "moment-timezone";
import useImmediate from "../hooks/useImmediate";
import chartTypesMap from "../defs/chartTypesMap";

export default function ChartLoader({
    chartType,
    aggregation,
    location,
    time,
}) {
    const api = useContext(ApiContext);
    const loc = useContext(LocationContext);

    const [data, setData] = useState(null);

    useImmediate(() => setData(null), [aggregation, location, time]);

    useEffect(() => {
        const refresh = aggregation.refresh;
        let cancel = false;
        let timer = null;
        let prevDataStr = "";

        async function call() {
            let url = `${aggregation.url}?locationId=${location}`;
            let timezone = loc.byId[location].timezone ?? "UTC";
            if (aggregation.time && time) {
                const startFn = aggregation.time.find((t) => t.name === time)
                    .start;
                const startTime = startFn(moment().tz(timezone));
                const endTime = moment().tz(timezone);
                url += `&startDateTime=${startTime.format()}&endDateTime=${endTime.format()}`;
            }

            if (cancel) return;
            try {
                // if (aggregation.key === "barSales") {
                //     const data = {
                //         categories: ["Bar A", "Bar B", "Bar C", "Bar D", "Super Duper Long Bar Name Here Really Too Long To Display", "Bar E"],
                //         series: [
                //             { x: "03/03", y: [19, 4, 2, 16, 4, 3] },
                //             { x: "03/04", y: [28, 5, 6, 22, 16, 27] },
                //             { x: "03/05", y: [17, 12, 9, 18, 14, 17] },
                //             { x: "03/06", y: [30, 2, 1, 28, 13, 19] },
                //             { x: "03/07", y: [8, 10, 13, 14, 16, 8] },
                //             { x: "03/08", y: [24, 16, 27, 18, 7, 12] },
                //             { x: "03/09", y: [45, 3, 14, 29, 24, 22] },
                //             { x: "03/10", y: [17, 1, 9, 11, 12, 21] }
                //         ]
                //     };
                //     setData(data);
                //     return;
                // }
                const newData = await api.get(url);
                const newDataStr = JSON.stringify(newData);
                if (!cancel && prevDataStr !== newDataStr) {
                    prevDataStr = newDataStr;
                    setData(newData);
                }
            } catch (e) {
                console.error("Failed to fetch data: " + e.message);
            }
            if (refresh > 0) timer = setTimeout(call, refresh * 1000);
        }

        call();
        return () => {
            cancel = true;
            clearTimeout(timer);
        };
    }, [api, aggregation, location, time]);

    const [Chart, props] = useMemo(() => {
        if (!chartType) return [null];
        const [type, argString] = chartType.split(":");
        const flags = (argString ?? "").split(",").reduce((acc, v) => {
            acc[v] = true;
            return acc;
        }, {});
        const props = {
            ...aggregation.chartProps,
            ...flags,
        };
        return [chartTypesMap[type], props];
    }, [chartType, aggregation]);

    const isDataEmpty = useMemo(
        () => data && Array.isArray(data) && data.length === 0,
        [data]
    );

    if (isDataEmpty) return <div className={styles.noData}>[ No data ]</div>;

    if (!data) return <Loader />;

    return (
        <ResponsiveComponent>
            {Chart && data && !isDataEmpty && <Chart data={data} {...props} />}
        </ResponsiveComponent>
    );
}
