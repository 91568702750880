import AreaChart from "../components/charts/AreaChart";
import BarChart from "../components/charts/BarChart";
import StackedBarChart from "../components/charts/StackedBarChart";
import PieChart from "../components/charts/PieChart";
import ScatterChart from "../components/charts/ScatterChart";
import BottleChart from "../components/charts/BottleChart";
import PpmChart from "../components/charts/PpmChart";

const chartTypes = {
    "area": AreaChart,
    "bar": BarChart,
    "stackedBar": StackedBarChart,
    "pie": PieChart,
    "scatter": ScatterChart,
    "bottle": BottleChart,
    "ppm": PpmChart
};

export default chartTypes;
