import React, {useState, useCallback} from "react";
import styles from "./SidebarList.module.css";

export default function Sidebar({items, selected, onClickItem, title}) {
    const [isExpanded, setIsExpanded] = useState(true);
    const handleLocationsExpandedToggle = useCallback(() => setIsExpanded(!isExpanded),
        [isExpanded]);

    const handleClickItem = useCallback(e => onClickItem(e.target.dataset.key), [onClickItem]);

    return (
        <div className={`${styles.list} ${isExpanded ? styles.expanded : ""}`}>
            <div onClick={handleLocationsExpandedToggle}>{title}</div>
            {isExpanded &&
            <ul>
                {items.map(i => <li key={i.key} data-key={i.key} onClick={handleClickItem}
                                    className={`${i.indent ? styles.indent : ""} ${selected === i.key ? styles.active : ""}`}
                                    title={i.text}>{i.text}</li>)}
            </ul>
            }
        </div>
    );
}