import React from "react";
import { useResizeDetector } from 'react-resize-detector';
import styles from "./ResponsiveComponent.module.css";

export default function ResponsiveComponent({ children }) {
    const { width, height, ref } = useResizeDetector();

    return (
        <div className={styles.outer}>
            <div ref={ref} className={styles.inner}>
                { children && width && height &&
                    React.cloneElement(children, {width: Math.floor(width), height: Math.floor(height)}) }
            </div>
        </div>
    )
}