import React from "react";

export default function SkyproLogo({className}) {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="75 164 417 101" className={className}>
            <defs>
                <style>
                    {".cls-1,.cls-2{fill:#8fc3ea;}.cls-2{font-size:73.25px;font-family:Roboto, Roboto;font-weight:300;}"}
                </style>
            </defs>
            <path className="cls-1"
                  d="M117.46,219.54a14,14,0,0,0-2.45-4.4,17.59,17.59,0,0,0-3.8-3.35,29.59,29.59,0,0,0-4.85-2.53l-8-3.36c-1.21-.48-2.39-1-3.53-1.49a17.15,17.15,0,0,1-3-1.72,7.79,7.79,0,0,1-2.08-2.18,5.32,5.32,0,0,1-.77-2.94,6,6,0,0,1,2.49-5.12,11.1,11.1,0,0,1,6.75-1.85,17.34,17.34,0,0,1,6.93,1.35,24.47,24.47,0,0,1,5.93,3.72l5.44-6.71a25.92,25.92,0,0,0-8.25-5.48,25.6,25.6,0,0,0-10.05-2,24.09,24.09,0,0,0-8,1.26A19.32,19.32,0,0,0,84,186.25a16.65,16.65,0,0,0-4.12,5.3,14.72,14.72,0,0,0-1.49,6.56,15.23,15.23,0,0,0,1,5.8,15.52,15.52,0,0,0,2.72,4.44A17.21,17.21,0,0,0,86,211.61a30.21,30.21,0,0,0,4.35,2.27l8.06,3.53c1.33.54,2.57,1.09,3.71,1.63a15.45,15.45,0,0,1,3,1.77A7,7,0,0,1,107,223a6.46,6.46,0,0,1,.68,3.08,6.66,6.66,0,0,1-2.67,5.52q-2.68,2.09-7.66,2.08a19.66,19.66,0,0,1-8.24-1.85A26.53,26.53,0,0,1,81.81,227l-6.07,7.16A30.46,30.46,0,0,0,97,242.86a26.59,26.59,0,0,0,8.92-1.4,20.51,20.51,0,0,0,6.7-3.81,16,16,0,0,0,5.67-12.36A17.65,17.65,0,0,0,117.46,219.54Z"/>
            <polygon className="cls-1"
                     points="146.13 215.51 161.31 197.44 149.93 197.44 133.49 217.85 133.22 217.85 133.22 178.02 123.01 178.02 123.01 241.79 133.22 241.79 133.22 230.5 140.17 222.55 151.55 241.79 162.84 241.79 146.13 215.51"/>
            <path className="cls-1"
                  d="M196.91,197.44l-6.68,21.77q-.81,3-1.67,6.19c-.58,2.14-1.13,4.23-1.67,6.28h-.37c-.66-2.11-1.3-4.23-1.94-6.37s-1.28-4.17-1.94-6.1L175,197.44H164.39l17.44,43.81-.82,2.8a13.49,13.49,0,0,1-3.2,5.47,7.67,7.67,0,0,1-5.65,2.12,6.16,6.16,0,0,1-1.67-.23L169,251l-2.81,8.13a15.27,15.27,0,0,0,2.62.68A19.56,19.56,0,0,0,172,260a16.57,16.57,0,0,0,6.37-1.13c1.84-.75,4.38-1.83,5.79-3.25a21.94,21.94,0,0,0,3.75-5.1,47.84,47.84,0,0,0,3-6.78L206,197.44Z"/>
            <path className="cls-1"
                  d="M236.23,179.91c-1.57-.14-3.31-.22-5.24-.22q-8,0-12,4.09T215,196v1.23h-5.57v9.68H215v34.85h13.06V206.94h9.59v-9.68h-10v-.36q0-4.32,1.35-5.83t5.18-1.5a12.21,12.21,0,0,1,3,.35c.93.24,1.68.45,2.26.64h1V180.49A41.79,41.79,0,0,0,236.23,179.91Z"/>
            <rect className="cls-1" x="244.84" y="180.09" width="12.51" height="61.7"/>
            <path className="cls-1"
                  d="M308.73,217.9c-3.37,0-6.66,0-9.65,0a11.69,11.69,0,1,1-13-13.14c0-4.35,0-9.36-.11-14.31a26,26,0,1,0,27.41,27.51Z"/>
            <path className="cls-1"
                  d="M289.1,176.9q.06,3.16.1,6.32c17.07,0,31.13,14,32,31.82h6.15C326.71,193.53,309.68,176.49,289.1,176.9Z"/>
            <path className="cls-1"
                  d="M289.1,164.13v.27c0,2.21.08,4.43.11,6.63,23.45-.14,42.76,19.41,43.46,44h7.56C339.32,186.33,316.58,163.59,289.1,164.13Z"/>
            <path className="cls-1" d="M289.1,190.45V215h24.46C313,201.31,302.22,190.44,289.1,190.45Z"/>
            <text className="cls-2" transform="translate(353.2 242.36)">PRO</text>
        </svg>        
    );
}