import React, { useContext, useState, useEffect, useMemo } from "react";
import AuthContext from "./AuthContext";
import Auth from "@aws-amplify/auth";
import gql from "graphql-tag";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import config from "../utils/appsync/aws-exports";
import { GetUserQuery, GetUserQueryVariables } from "../utils/appsync/API";
import LoginPage from "../pages/LoginPage";

const LocationContext = React.createContext<any>(null);

const getUser = /* GraphQL */ `
    query GetUser($username: String!) {
        getUser(username: $username) {
            username
            email
            _version
            role
            timezone
            primaryLocation {
                id
                name
                address
                type
                timezone
                thumbnail
            }
            bars {
                id
                name
                location
                thumbnail
                device
            }
            companyID
            createdAt
            updatedAt
            owner
        }
    }
`;

export function LocationWrapper({ children }: any) {
    const user = useContext<any>(AuthContext);
    const [root, setRoot] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const client = new AWSAppSyncClient({
        url: config.aws_appsync_graphqlEndpoint,
        region: config.aws_appsync_region,
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () =>
                (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
        disableOffline: true,
    });

    useEffect(() => {
        (async function () {
            setLoading(true);
            try {
                let result = await client.query<
                    GetUserQuery,
                    GetUserQueryVariables
                >({
                    query: gql(getUser),
                    variables: {
                        username: user!.username,
                    },
                });
                if (!result.data.getUser) {
                    setRoot(null);
                    return;
                }
                let lUser = result.data.getUser;
                let timezone =
                    lUser.primaryLocation?.timezone ?? lUser.timezone ?? "UTC";
                let locations = lUser.bars?.map((l) => ({
                    locationId: l?.id,
                    name: l?.name,
                    level: 1,
                    timezone: timezone,
                }));
                let root = {
                    locationId: lUser.primaryLocation?.id,
                    name: lUser.primaryLocation?.name,
                    level: 0,
                    locations: locations,
                    timezone: lUser.primaryLocation?.timezone,
                    user: lUser,
                };
                setRoot(root);
            } catch (ex) {
                console.error(ex);
            }
            setLoading(false);
        })();
    }, [user]);

    const locations = useMemo(() => {
        if (!root) return null;
        let flat = root.locations;
        return {
            root,
            flat,
            byId: flat.reduce(
                (acc: any, cur: any) => ({ ...acc, [cur.locationId]: cur }),
                {}
            ),
        };
    }, [root]);

    if (loading) {
        return <></>;
    }

    if (!loading && locations !== null) {
        return (
            <LocationContext.Provider value={locations}>
                {children}
            </LocationContext.Provider>
        );
    }
    return <LoginPage />;
}

export default LocationContext;
