// tslint:disable
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      accountId
      locationId
      name
      brand
      ean13
      upc
      thumbnail
      category
      size
      unitOfSize
      price
      currency
      inventoies {
        items {
          id
          productId
          locationId
          stock
        }
        nextToken
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        locationId
        name
        brand
        ean13
        upc
        thumbnail
        category
        size
        unitOfSize
        price
        currency
        inventoies {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      productId
      locationId
      stock
    }
  }
`;
export const listInventorys = /* GraphQL */ `
  query ListInventorys(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        locationId
        stock
      }
      nextToken
    }
  }
`;
export const listByAccountIdLocationId = /* GraphQL */ `
  query ListByAccountIdLocationId(
    $accountId: String
    $locationId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByAccountIdLocationId(
      accountId: $accountId
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        name
        brand
        ean13
        upc
        thumbnail
        category
        size
        unitOfSize
        price
        currency
        inventoies {
          items {
            id
            productId
            locationId
            stock
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listByLocationIdName = /* GraphQL */ `
  query ListByLocationIdName(
    $locationId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByLocationIdName(
      locationId: $locationId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        name
        brand
        ean13
        upc
        thumbnail
        category
        size
        unitOfSize
        price
        currency
        inventoies {
          items {
            id
            productId
            locationId
            stock
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listByLocationIdEan13 = /* GraphQL */ `
  query ListByLocationIdEan13(
    $locationId: String
    $ean13: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByLocationIdEan13(
      locationId: $locationId
      ean13: $ean13
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        name
        brand
        ean13
        upc
        thumbnail
        category
        size
        unitOfSize
        price
        currency
        inventoies {
          items {
            id
            productId
            locationId
            stock
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listByProductIdLocationId = /* GraphQL */ `
  query ListByProductIdLocationId(
    $productId: ID
    $locationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByProductIdLocationId(
      productId: $productId
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        locationId
        stock
      }
      nextToken
    }
  }
`;
