import gql from "graphql-tag";
import Auth from "@aws-amplify/auth";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsconfig from "./aws-exports";
import {
    CreateSpoutGen4LiquorInput,
    CreateSpoutGen4LiquorMutation,
    CreateSpoutGen4LiquorMutationVariables,
    ListSpoutGen4LiquorByLocationIdQueryVariables,
    ListSpoutGen4LiquorByLocationIdQuery,
    ListSpoutGen4LiquorByLocationIdRemainingQuery,
    ListSpoutGen4LiquorByLocationIdRemainingQueryVariables,
    ListSpoutGen4LiquorByLocationIdNameQuery,
    ListSpoutGen4LiquorByLocationIdNameQueryVariables,
    UpdateSpoutGen4LiquorMutation,
    UpdateSpoutGen4LiquorMutationVariables,
    ListSpoutGen4EventPourDatByLocationIdDatetimeQuery,
    ListSpoutGen4EventPourDatByLocationIdDatetimeQueryVariables,
    ModelSortDirection,
} from "./API";
import {
    createSpoutGen4Liquor,
    updateSpoutGen4Liquor,
} from "./graphql/mutations";
import {
    listSpoutGen4LiquorByLocationId,
    listSpoutGen4LiquorByLocationIdRemaining,
    listSpoutGen4LiquorByLocationIdName,
    listSpoutGen4EventPourDatByLocationIdDatetime,
} from "./graphql/queries";

class Gen4Graphql {
    private client = new AWSAppSyncClient({
        url: awsconfig.aws_appsync_graphqlEndpoint,
        region: awsconfig.aws_appsync_region,
        auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => Auth.currentCredentials(),
        },
        disableOffline: true,
    });

    async addLiquor(liquor: CreateSpoutGen4LiquorInput) {
        let result = await this.client.mutate<
            CreateSpoutGen4LiquorMutation,
            CreateSpoutGen4LiquorMutationVariables
        >({
            mutation: gql(createSpoutGen4Liquor),
            variables: {
                input: liquor,
            },
        });
        return result?.data?.createSpoutGen4Liquor;
    }

    async listLiquor(
        locationId: string,
        nextToken?: string | null | undefined
    ) {
        let result = await this.client.query<
            ListSpoutGen4LiquorByLocationIdQuery,
            ListSpoutGen4LiquorByLocationIdQueryVariables
        >({
            query: gql(listSpoutGen4LiquorByLocationId),
            variables: {
                locationId: locationId,
                nextToken: nextToken,
                limit: 20,
            },
        });
        return result.data.listSpoutGen4LiquorByLocationId;
    }

    async listLiquorRemainingLessThanZero(
        locationId: string,
        nextToken?: string | null | undefined
    ) {
        let result = await this.client.query<
            ListSpoutGen4LiquorByLocationIdRemainingQuery,
            ListSpoutGen4LiquorByLocationIdRemainingQueryVariables
        >({
            query: gql(listSpoutGen4LiquorByLocationIdRemaining),
            variables: {
                locationId: locationId,
                remaining: {
                    le: 0,
                },
                nextToken: nextToken,
                limit: 1000,
            },
        });
        return result.data.listSpoutGen4LiquorByLocationIdRemaining;
    }

    async listLiqourByName(locationId: string, name: string) {
        let result = await this.client.query<
            ListSpoutGen4LiquorByLocationIdNameQuery,
            ListSpoutGen4LiquorByLocationIdNameQueryVariables
        >({
            query: gql(listSpoutGen4LiquorByLocationIdName),
            variables: {
                locationId: locationId,
                name: {
                    eq: name,
                },
                limit: 50,
            },
        });
        return result.data.listSpoutGen4LiquorByLocationIdName;
    }

    async updateRemaining(id: string, remaining: number) {
        let result = await this.client.mutate<
            UpdateSpoutGen4LiquorMutation,
            UpdateSpoutGen4LiquorMutationVariables
        >({
            mutation: gql(updateSpoutGen4Liquor),
            variables: {
                input: {
                    id: id,
                    remaining: remaining,
                },
            },
        });
        return result.data?.updateSpoutGen4Liquor;
    }

    async listSpoutGen4PourEventData(
        locationId: string,
        startDate: string,
        enddate: string,
        limit: number = 50,
        nextToken?: string | null | undefined
    ) {
        let result = await this.client.query<
            ListSpoutGen4EventPourDatByLocationIdDatetimeQuery,
            ListSpoutGen4EventPourDatByLocationIdDatetimeQueryVariables
        >({
            query: gql(listSpoutGen4EventPourDatByLocationIdDatetime),
            variables: {
                locationId: locationId,
                datetime: {
                    between: [startDate, enddate],
                },
                limit: limit,
                nextToken: nextToken,
                sortDirection: ModelSortDirection.DESC
            },
        });
        return result.data.listSpoutGen4EventPourDatByLocationIdDatetime;
    }
}

export default Gen4Graphql;
