import gql from 'graphql-tag';
import Auth from '@aws-amplify/auth';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from './aws-exports';
import { listByProductIdLocationId, getProduct,  listByLocationIdName, listByAccountIdLocationId, listByLocationIdEan13 } from './graphql/queries';
import {  CreateInventoryMutation, CreateProductMutation, CreateProductMutationVariables, CreateInventoryMutationVariables, ListByProductIdLocationIdQuery, ListByProductIdLocationIdQueryVariables, UpdateInventoryMutation, UpdateInventoryMutationVariables, GetProductQuery, GetProductQueryVariables, ListByLocationIdNameQuery, ListByLocationIdNameQueryVariables, ListByAccountIdLocationIdQuery, ListByAccountIdLocationIdQueryVariables, ListByLocationIdEan13Query, ListByLocationIdEan13QueryVariables } from './API';
import { createProduct, createInventory, updateInventory } from './graphql/mutations';

class Inventory {

    private client = new AWSAppSyncClient({
        url: awsconfig.aws_appsync_graphqlEndpoint,
        region: awsconfig.aws_appsync_region,
        auth: {
            type: AUTH_TYPE.AWS_IAM,
            credentials: () => Auth.currentCredentials(),
        },
        disableOffline: true
    });

    async getProduct(locationId: string, accountId: string){
        let result = await this.client.query<ListByAccountIdLocationIdQuery, ListByAccountIdLocationIdQueryVariables>({ query: gql(listByAccountIdLocationId), variables:{
            accountId:accountId,
            locationId:{
                eq:locationId
            }
        } })
        return result.data.listByAccountIdLocationId!;
    }

    async getProductById(productId: string){
        let result = await this.client.query<GetProductQuery, GetProductQueryVariables>({ query: gql(getProduct), variables:{
            id : productId
        } })
        return result.data.getProduct;
    }

    async addProduct(product: any){
        let result = await this.client.mutate<CreateProductMutation, CreateProductMutationVariables>({ mutation: gql(createProduct), variables:{
            input:product
        }})
        return result.data?.createProduct;
    }
    async addStock(productId: string, locationId: string, stock: number){
        let result = await this.client.mutate<CreateInventoryMutation, CreateInventoryMutationVariables>({mutation: gql(createInventory), variables:{
            input:{
                locationId: locationId,
                productId: productId,
                stock: stock
            }
        }})
        return result.data?.createInventory;
    }

    async listInventory(productId: string, locationId: string){
        let result = await this.client.query<ListByProductIdLocationIdQuery, ListByProductIdLocationIdQueryVariables>({query: gql(listByProductIdLocationId), variables:{
            productId: productId,
            locationId:{
                eq: locationId
            }
        }})
        return result.data.listByProductIdLocationId;
    }

    async addInventory(productId: string, locationId: string, stock: number){
        let result = await this.client.mutate<CreateInventoryMutation, CreateInventoryMutationVariables>({mutation:gql(createInventory), variables: {
            input:{
                locationId: locationId,
                productId: productId,
                stock: stock
            }
        }})
        return result.data?.createInventory;
    }

    async updateInventory(inventoryId: string, stock : number){
        let result = await this.client.mutate<UpdateInventoryMutation, UpdateInventoryMutationVariables>({mutation:gql(updateInventory), variables: {
            input:{
                id: inventoryId,
                stock: stock
            }
        }})
        return result.data?.updateInventory;
    }

    async listProductByName(locationId: string, name: string){
        let result = await this.client.query<ListByLocationIdNameQuery, ListByLocationIdNameQueryVariables>({
            query: gql(listByLocationIdName),
            variables:{
                locationId : locationId,
                name:{
                    eq: name
                },
                limit: 1
            }
        })

        return result.data.listByLocationIdName;
    }

    async getProductByBarcode(locationId: string, barcode: string){
        let result = await this.client.query<ListByLocationIdEan13Query, ListByLocationIdEan13QueryVariables>({
            query: gql(listByLocationIdEan13),
            variables:{
                locationId: locationId,
                ean13: {
                    eq: barcode
                },
                limit: 1
            },
            
        })
        return result.data.listByLocationIdEan13
    }
}

export default Inventory

