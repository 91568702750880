import React from "react";
import Auth from "@aws-amplify/auth";

async function apiFetch(url, method, body) {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const response = await fetch(url, {
        method,
        body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
    if (!response.ok)
        throw new Error(
            `API returned ${response.status} ${response.statusText}`
        );
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.indexOf("application/json") === 0)
        return await response.json();
    else return await response.text();
}

async function download(url) {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const response = await fetch(url, {
        method: "GET",
        responseType: "blob",
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    if (!response.ok)
        throw new Error(
            `API returned ${response.status} ${response.statusText}`
        );
    return response;
}

// Convenience interface for making different types of calls to the API.
const api = {
    get(url) {
        return apiFetch(url, "GET");
    },
    post(url, body) {
        return apiFetch(url, "POST", body);
    },
    put(url, body) {
        return apiFetch(url, "PUT", body);
    },
    delete(url) {
        return apiFetch(url, "DELETE");
    },
    download(url) {
        return download(url);
    },
};

const ApiContext = React.createContext(null);

export function ApiWrapper({ children }) {
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export default ApiContext;
