/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpoutGen4EventData = /* GraphQL */ `
  query GetSpoutGen4EventData($id: ID!) {
    getSpoutGen4EventData(id: $id) {
      id
      userId
      locationId
      datetime
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      eventData
      liquorId
      liquorName
      ttl
      datetimeEventType
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpoutGen4EventDatas = /* GraphQL */ `
  query ListSpoutGen4EventDatas(
    $filter: ModelSpoutGen4EventDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        eventData
        liquorId
        liquorName
        ttl
        datetimeEventType
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpoutGen4EventPourData = /* GraphQL */ `
  query GetSpoutGen4EventPourData($id: ID!) {
    getSpoutGen4EventPourData(id: $id) {
      id
      userId
      locationId
      datetime
      liquorId
      ipadDatetime
      eventId
      spoutId
      engraving
      rsoc
      eventType
      liquorType
      pourVolume
      pourStatus
      volumeRemaining
      totalPourSuccess
      totalPourFailed
      totalPourAmount
      liquorName
      pourSelection
      unitPrice
      volumePrice
      ttl
      setpointVolume
      setpointPrice
      bottleSwitchStatus
      pourTime
      setpointTime
      errorCode
      cost
      category
      virtualBar
      ltaOnCharger
      ltaOnBottle
      proxDuringLiquidDetect1
      proxDuringLiquidDetect2
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpoutGen4EventPourDatas = /* GraphQL */ `
  query ListSpoutGen4EventPourDatas(
    $filter: ModelSpoutGen4EventPourDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventPourDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        liquorId
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        liquorType
        pourVolume
        pourStatus
        volumeRemaining
        totalPourSuccess
        totalPourFailed
        totalPourAmount
        liquorName
        pourSelection
        unitPrice
        volumePrice
        ttl
        setpointVolume
        setpointPrice
        bottleSwitchStatus
        pourTime
        setpointTime
        errorCode
        cost
        category
        virtualBar
        ltaOnCharger
        ltaOnBottle
        proxDuringLiquidDetect1
        proxDuringLiquidDetect2
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpoutGen4Liquor = /* GraphQL */ `
  query GetSpoutGen4Liquor($id: ID!) {
    getSpoutGen4Liquor(id: $id) {
      id
      accountId
      locationId
      productId
      name
      brand
      thumbnail
      setpoints
      prices
      remaining
      currency
      locationName
      pourtime
      size
      setpointsOz
      cost
      category
      virtualBar
      bottleSensor
      IQSReg05
      IQSReg06
      IQSReg07
      IQSReg08
      IQSReg09
      IQSReg0A
      IQSReg0B
      expDelta
      liquidWait
      createAt
      updateAt
      createdAt
      updatedAt
    }
  }
`;
export const listSpoutGen4Liquors = /* GraphQL */ `
  query ListSpoutGen4Liquors(
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4Liquors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpoutGen4Device = /* GraphQL */ `
  query GetSpoutGen4Device($id: ID!) {
    getSpoutGen4Device(id: $id) {
      id
      userId
      locationId
      liquorId
      createAt
      updateAt
      engraving
      spoutId
      volume
      onBottle
      virtualBar
      bottleSensor
      createdAt
      updatedAt
      liquor {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSpoutGen4Devices = /* GraphQL */ `
  query ListSpoutGen4Devices(
    $filter: ModelSpoutGen4DeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4Devices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        liquorId
        createAt
        updateAt
        engraving
        spoutId
        volume
        onBottle
        virtualBar
        bottleSensor
        createdAt
        updatedAt
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSpoutGen4EventDataByLocationId = /* GraphQL */ `
  query ListSpoutGen4EventDataByLocationId(
    $locationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4EventDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventDataByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        eventData
        liquorId
        liquorName
        ttl
        datetimeEventType
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4EventDatByLocationIdDatetime = /* GraphQL */ `
  query ListSpoutGen4EventDatByLocationIdDatetime(
    $locationId: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4EventDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventDatByLocationIdDatetime(
      locationId: $locationId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        eventData
        liquorId
        liquorName
        ttl
        datetimeEventType
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4EventDatByLocationIdDatetimeEventType = /* GraphQL */ `
  query ListSpoutGen4EventDatByLocationIdDatetimeEventType(
    $locationId: String
    $datetimeEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4EventDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventDatByLocationIdDatetimeEventType(
      locationId: $locationId
      datetimeEventType: $datetimeEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        eventData
        liquorId
        liquorName
        ttl
        datetimeEventType
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4EventPourDatByLocationIdDatetime = /* GraphQL */ `
  query ListSpoutGen4EventPourDatByLocationIdDatetime(
    $locationId: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4EventPourDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4EventPourDatByLocationIdDatetime(
      locationId: $locationId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        datetime
        liquorId
        ipadDatetime
        eventId
        spoutId
        engraving
        rsoc
        eventType
        liquorType
        pourVolume
        pourStatus
        volumeRemaining
        totalPourSuccess
        totalPourFailed
        totalPourAmount
        liquorName
        pourSelection
        unitPrice
        volumePrice
        ttl
        setpointVolume
        setpointPrice
        bottleSwitchStatus
        pourTime
        setpointTime
        errorCode
        cost
        category
        virtualBar
        ltaOnCharger
        ltaOnBottle
        proxDuringLiquidDetect1
        proxDuringLiquidDetect2
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4LiquorByLocationId = /* GraphQL */ `
  query ListSpoutGen4LiquorByLocationId(
    $locationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4LiquorByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4LiquorByLocationIdRemaining = /* GraphQL */ `
  query ListSpoutGen4LiquorByLocationIdRemaining(
    $locationId: String
    $remaining: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4LiquorByLocationIdRemaining(
      locationId: $locationId
      remaining: $remaining
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4LiquorByProductId = /* GraphQL */ `
  query ListSpoutGen4LiquorByProductId(
    $productId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4LiquorByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4LiquorByName = /* GraphQL */ `
  query ListSpoutGen4LiquorByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4LiquorByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4LiquorByLocationIdName = /* GraphQL */ `
  query ListSpoutGen4LiquorByLocationIdName(
    $locationId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4LiquorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4LiquorByLocationIdName(
      locationId: $locationId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        locationId
        productId
        name
        brand
        thumbnail
        setpoints
        prices
        remaining
        currency
        locationName
        pourtime
        size
        setpointsOz
        cost
        category
        virtualBar
        bottleSensor
        IQSReg05
        IQSReg06
        IQSReg07
        IQSReg08
        IQSReg09
        IQSReg0A
        IQSReg0B
        expDelta
        liquidWait
        createAt
        updateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpoutGen4DeviceByLocationId = /* GraphQL */ `
  query ListSpoutGen4DeviceByLocationId(
    $locationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpoutGen4DeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpoutGen4DeviceByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        locationId
        liquorId
        createAt
        updateAt
        engraving
        spoutId
        volume
        onBottle
        virtualBar
        bottleSensor
        createdAt
        updatedAt
        liquor {
          id
          accountId
          locationId
          productId
          name
          brand
          thumbnail
          setpoints
          prices
          remaining
          currency
          locationName
          pourtime
          size
          setpointsOz
          cost
          category
          virtualBar
          bottleSensor
          IQSReg05
          IQSReg06
          IQSReg07
          IQSReg08
          IQSReg09
          IQSReg0A
          IQSReg0B
          expDelta
          liquidWait
          createAt
          updateAt
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
